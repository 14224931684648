import React, { useCallback, useEffect, useState } from 'react';
import Modal from '../Modal';
import tick from '../resources/images/tick1.svg';
import { Link, ModalCloseButton, ModalContainer } from '../styling/Components';
import {
  ContextValues,
  RESET_STATE,
  SET_ACCOUNT_ID,
  SET_ACTIVE_BOARD,
  Synchronize,
} from './../store';
import { IDBPDatabase } from 'idb';
import { Database } from './../typings/database';

import axios from 'axios';
import { any } from 'prop-types';
import { useTranslation } from 'react-i18next';
import Spinner from '../resources/Spinner';
import { buildKanbanaRedirectUrl } from '../utils/externalLink';

type Props = {
  onDismiss: () => void;
  CheckoutForm: () => void;
  App: () => void;
  database?: IDBPDatabase<Database>;
  store?: ContextValues;
  synchronize?: Synchronize;
  onSignOutClick?: () => void;
  subscription?: boolean;
  IsKanbanaPremiumEnabled?: boolean;
  subcode?: boolean;
  Id?: string;
};

interface Subss {
  description: string;
  Price: string;
  Price_id: string;
  Id: string;
  ExpireDateInSeconds: string;
  Title: string;
  isSubscribed: boolean;
}

type subState = {
  IsKanbanaPremiumEnabled?: boolean;
};
export const SubModal: React.FC<Props> = ({
  onDismiss,
  IsKanbanaPremiumEnabled,
}) => {
  const { t } = useTranslation();
  const [subState, IsSubState] = useState(true);
  const [sub, subData] = useState<Subss[]>([]);
  const [session, setSession] = useState('');
  const [subscriptionStatus, setSubStatus] = useState('active');

  const [cancelSub, setSubVisible] = useState(false);
  const [alertSub, setAlertSub] = useState(false);
  const [buttonPrice, setButtonPrice] = useState(false);
  const [cancelPrice, setCancelPrice] = useState(false);
  const [loading, setLoader] = useState(false);
  const language = localStorage.getItem('lang');

  useEffect(() => {
    const isLoggedin = localStorage.getItem(
      'AccountInfo.IsKanbanaPremiumEnabled',
    );
    if (isLoggedin) {
      IsSubState(true);
      IsKanbanaPremiumEnabled = false;
    } else if (!isLoggedin) {
      IsSubState(false);
      IsKanbanaPremiumEnabled = false;
    }
  }, []);

  const handleKeyDown = useCallback(
    (e: any) => {
      e.stopPropagation();
      if (e.key === 'Escape') {
        onDismiss();
      }
    },
    [onDismiss],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  });

  let account: any = localStorage.getItem('accountId');

  const accountData = JSON.parse(account);

  useEffect(() => {
    let account: any = localStorage.getItem('accountId');
    const accountData = JSON.parse(account);
    if (accountData === null) {
      return;
    } else {
      setLoader(true);
      axios
        .get(
          `https://stripe.kanbana.com/api/subscription?Account_id=${accountData.accountId}`,
        )

        .then(response => {
          setLoader(false);
          if (Array.isArray(response.data.planList)) {
            subData(response.data.planList);
            response.data.planList.forEach((element: any) => {
              if (element.isSubscribed === true) {
              }
            });
            localStorage.setItem(
              'planList',
              JSON.stringify(response.data.planList),
            );

            localStorage.setItem(
              'isKanbanaPremiumEnabled',
              response.data.isKanbanaPremiumEnabled,
            );
            localStorage.setItem(
              'subscriptionType',
              response.data.subscriptionType,
            );
            localStorage.setItem(
              'subscriptionStatus',
              response.data.subscriptionStatus,
            );
            localStorage.setItem(
              'ExpireDateInSeconds',
              response.data.planList.ExpireDateInSeconds,
            );
          } else {
          }
        });
    }
  }, []);

  const handleClick = (Price_id: any) => {
    const data = {
      Account_id: accountData.accountId,
      Price_id: Price_id,
    };
    setLoader(true);
    axios
      .post(
        `https://stripe.kanbana.com/api/subscription?Account_id=${accountData.accountId}`,
        data,
      )
      .then(response => {
        setLoader(false);
        if (Array.isArray(response)) {
          setSession(response.data.sessionUrl);
        } else {
          setLoader(false);
          setSession(response.data.sessionUrl);
          window.open(response.data.sessionUrl, '_self');
        }
      });
  };
  const handleClickUpgrade = (Price_id: any) => {
    const data = {
      Account_id: accountData.accountId,
      Price_id: Price_id,
    };

    axios
      .post(`https://stripe.kanbana.com/api/subscription/upgrade`, data)
      .then(response => {
        localStorage.setItem('message', response.data.message);

        let alert1 = localStorage.getItem('message');
        let alert2 = alert1;
        alert(alert2);
        setAlertSub(false);
        setSubVisible(false);
        onDismiss();
      })
      .catch(error => { });
  };

  const cancelSubscription = () => {
    const data = {
      Account_id: accountData.accountId,
    };

    axios
      .post('https://stripe.kanbana.com/api/subscription/cancel ', data)
      .then(response => {
        if (response.data.message) {
        } else {
        }
      })
      .catch(error => console.error(error));

    setSubVisible(false);
    setCancelPrice(true);
    onDismiss();
    return;
  };

  const hideAccount = () => {
    setSubVisible(false);
    setAlertSub(false);
  };
  const cancelWarn = () => {
    setSubVisible(true);
  };

  let kanbaNPremium: any = localStorage.getItem('isKanbanaPremiumEnabled');
  let kanbanPremium: any = kanbaNPremium;
  let data: any = localStorage.getItem('AccountInfo');
  const accountInfo = JSON.parse(data);

  let typess: any = localStorage.getItem('subscriptionType');
  const subscriptionType1 = typess;
  let status1: any = localStorage.getItem('subscriptionStatus');
  const subscriptionStatus1 = status1;
  function getExpDate(ExpireDateInSeconds: any) {
    var date = new Date(ExpireDateInSeconds * 1000);

    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var date1 = month + '/' + day + '/' + year;
    if (ExpireDateInSeconds) {
    } else {
      date1 = '';
    }
    return date1;
  }
  function cancelAlert() {
    setAlertSub(true);
  }


  return (
    <>
      <Modal>
        <ModalContainer
          style={{
            backgroundColor: '#eaeaea',
            width: 600,
            position: 'relative',
          }}>
          <ModalCloseButton onClick={onDismiss} />
          <div
            style={{
              width: 620,
              height: 39,
              backgroundColor: '#dfd9d9',
              textAlign: 'center',
              fontSize: 28,
              paddingTop: 10,
              fontWeight: 'bold',
            }}>
            {t('Premium.KanbanaPremium')}{' '}
          </div>

          <div style={{ backgroundColor: '#eaeaea', width: '85%' }}>
            <p>{t('Modal.pleased')}</p>
            <p>{t('Modal.OnTop')} </p>
            <li>{t('Modal.Cards')}</li>
            <li style={{ marginTop: 10 }}>
              {t('Modal.Task')} <span>{t('Modal.Rtask')}</span>
            </li>
            <li style={{ marginTop: 10 }}>
              {t('Modal.Support')} <span>&#x1F44D;</span>{' '}
            </li>
            <br></br>
            {loading === true && <Spinner />}
            <div className="App" style={{ textAlign: 'center' }}>
              {kanbanPremium === '1' &&
                subscriptionType1 === 'inApp' &&
                subscriptionStatus === 'active' && (
                  <>
                    <button
                      style={{
                        height: 33,
                        color: 'white',
                        backgroundColor: '#f66e00',
                        borderRadius: 5,
                        cursor: 'grab',
                        marginRight: 25,
                        marginBottom: 12,
                        marginTop: 62,
                        border: 'none',
                      }}>
                      {t('Modal.alreadySubscribed')}
                    </button>
                    <br></br>

                    <span style={{ fontWeight: 400, fontSize: 15 }}>
                      {' '}
                      <i>{t('Modal.Subscription')}</i>
                    </span>
                  </>
                )}
            </div>

            <div className="App" style={{ textAlign: 'center' }}>
              <br></br>
              <br></br>
              <br></br>
              {kanbanPremium === '0' && (
                <>
                  <ul style={{ marginLeft: -25 }}>
                    {[sub] &&
                      sub.map(subss => (
                        <button
                          onClick={() => handleClick(subss?.Price_id)}
                          key={subss?.Id}
                          style={{
                            width: 154,
                            height: 33,
                            color: 'white',
                            backgroundColor: '#f66e00',
                            borderRadius: 5,
                            cursor: 'pointer',
                            marginRight: 25,
                            marginTop: 70,
                            marginBottom: 10,
                            border: 'none',
                          }}>
                          {subss?.Price}
                        </button>
                      ))}
                  </ul>
                </>
              )}
              <div>
                {kanbanPremium === '0' && (
                  <div
                    style={{
                      fontSize: 13,
                      marginTop: 10,
                      textAlign: 'center',
                    }}>
                    <span>{t('Premium.FreeTrailText')}</span>
                  </div>
                )}
              </div>
            </div>
            <div className="App" style={{ textAlign: 'center' }}>
              <div
                className="App"
                style={{ textAlign: 'center', marginTop: 10 }}>
                {kanbanPremium === '1' &&
                  subscriptionType1 === 'stripe' &&
                  subscriptionStatus1 !== 'canceled' && (
                    <>
                      <span>
                        {' '}
                        {[sub] &&
                          sub.map(subss => (
                            <span key={subss?.Price_id}>
                              {' '}
                              {subss.isSubscribed === true &&
                                subss.Title === 'Yearly' && (
                                  <i>
                                    {' '}
                                    <b>*{subss.Title}</b> subscription has been
                                    taken{' '}
                                  </i>
                                )}
                            </span>
                          ))}
                      </span>
                      <span>
                        {' '}
                        {[sub] &&
                          sub.map(subss => (
                            <span key={subss?.Price_id}>
                              {' '}
                              {subss.isSubscribed === true &&
                                subss.Title === 'Monthly' && (
                                  <i>
                                    <b>*{subss.Title}</b> subscription has been
                                    taken{' '}
                                  </i>
                                )}
                            </span>
                          ))}
                      </span>
                    </>
                  )}
                {kanbanPremium === '1' &&
                  subscriptionType1 === 'stripe' &&
                  subscriptionStatus1 === 'canceled' && (
                    <span> *Your subscription has been cancelled </span>
                  )}
              </div>

              {kanbanPremium === '1' && subscriptionType1 === 'stripe' && (
                <>
                  <ul style={{ marginLeft: -16 }}>
                    {[sub] &&
                      sub.map(subss => (
                        <button
                          disabled={
                            (subss.isSubscribed === true &&
                              subss.Title === 'Yearly') ||
                            (subss.isSubscribed === false &&
                              subss.Title === 'Monthly') ||
                            (subss.isSubscribed === true &&
                              subss.Title === 'Monthly')
                          }
                          onClick={() => cancelAlert()}
                          key={subss?.Price_id}
                          style={{
                            width: 154,
                            height: 33,
                            color: 'white',
                            backgroundColor: '#f66e00',
                            borderRadius: 5,
                            cursor: 'pointer',
                            marginRight: 25,
                            marginTop: 5,
                            marginBottom: -20,
                            position: 'relative',
                            border: 'none',
                          }}>
                          {subss.isSubscribed === true && (
                            <img
                              style={{
                                height: 20,
                                width: 20,
                                position: 'absolute',
                                right: 0,
                                top: 4,
                              }}
                              src={tick}></img>
                          )}
                          {subss?.Price}
                        </button>
                      ))}
                  </ul>

                  {[sub] &&
                    sub.map(subss => (
                      <div>
                        {subss.isSubscribed === true &&
                          subss.Title === 'Monthly' && (
                            <span style={{ fontSize: 10 }}>
                              <i>
                                {' '}
                                *Your subscription will be expired but stay
                                active until{' '}
                                <span>
                                  {' '}
                                  {[sub] &&
                                    sub.map(subss => (
                                      <span key={subss?.Price_id}>
                                        {' '}
                                        <b>
                                          {getExpDate(
                                            subss?.ExpireDateInSeconds,
                                          )}{' '}
                                        </b>{' '}
                                      </span>
                                    ))}
                                </span>
                                . After this date, your subscription will be
                                automatically expired and you can buy new
                                subscription if you want to use our service
                              </i>
                            </span>
                          )}
                      </div>
                    ))}
                  {[sub] &&
                    sub.map(subss => (
                      <>
                        <div>
                          {subss.isSubscribed === true &&
                            subss.Title === 'Yearly' && (
                              <span
                                style={{
                                  fontSize: 10,
                                  marginTop: 12,
                                  marginLeft: -16,
                                }}
                                key={subss?.Price_id}>
                                <i>
                                  {subss.Title} - Thanks for being a valued
                                  member! You're already subscribed to our{' '}
                                  {subss.Title} and enjoying all the benefits of
                                  our premium content
                                </i>
                              </span>
                            )}{' '}
                        </div>
                      </>
                    ))}
                  <div style={{ alignItems: 'center' }}>
                    {kanbanPremium === '1' &&
                      subscriptionStatus1 !== 'canceled' && (
                        <button
                          onClick={() => cancelWarn()}
                          style={{
                            width: 154,
                            height: 33,
                            color: 'white',
                            backgroundColor: '#f66e00',
                            borderRadius: 5,
                            cursor: 'pointer',
                            marginRight: 0,
                            marginTop: 2,
                            border: 'none',
                          }}>
                          Cancel Subscription
                        </button>
                      )}
                  </div>
                </>
              )}

              {[sub] &&
                sub.map(subss => (
                  <>
                    {kanbanPremium === '1' &&
                      subscriptionStatus1 === 'active' &&
                      buttonPrice === false && (
                        <div>
                          {subss.isSubscribed === true && (
                            <span
                              style={{
                                fontSize: 10,
                                marginTop: 12,
                                marginLeft: -16,
                              }}
                              key={subss?.Price_id}>
                              <i>
                                {subss.Title} - Thanks for being a valued
                                member! You're already subscribed to our{' '}
                                {subss.Title} and enjoying all the benefits of
                                our premium content
                              </i>
                            </span>
                          )}{' '}
                        </div>
                      )}
                  </>
                ))}

              {kanbanPremium === '1' ||
                subscriptionType1 === 'stripe' ||
                (subscriptionStatus1 === 'canceled' && (
                  <>
                    <ul>
                      {[sub] &&
                        sub.map(subss => (
                          <button
                            disabled={subss.isSubscribed === true}
                            onClick={() => handleClickUpgrade(subss?.Price_id)}
                            key={subss?.Price_id}
                            style={{
                              width: 154,
                              height: 33,
                              color: 'white',
                              backgroundColor: '#f66e00',
                              borderRadius: 5,
                              cursor: 'pointer',
                              marginRight: 25,
                              marginBottom: 1,
                            }}>
                            {subss?.Price}
                          </button>
                        ))}
                    </ul>

                    <span style={{ fontSize: 10 }}>
                      <i>
                        {' '}
                        *Your subscription will be expired but stay active until{' '}
                        <span>
                          {' '}
                          {[sub] &&
                            sub.map(subss => (
                              <span key={subss?.Price_id}>
                                {' '}
                                <b>
                                  {getExpDate(subss?.ExpireDateInSeconds)}{' '}
                                </b>{' '}
                              </span>
                            ))}
                        </span>
                        . After this date, your subscription will be
                        automatically expired and you can buy new subscription
                        if you want to use our services
                      </i>
                    </span>

                    <div></div>
                  </>
                ))}
            </div>
            <div
              style={{
                position: 'absolute',
                left: 0,
                right: 0,
                bottom: 0,
                textAlign: 'center',
              }}>
              <span
                style={{
                  marginRight: 10,
                  textDecorationLine: 'underline',
                  color: 'black',
                  fontSize: 13,
                }}>
                <Link
                  href={buildKanbanaRedirectUrl(language ?? 'en', '/privacy')}
                  target="_blank"
                  style={{ color: 'black' }}>
                  {t('Premium.PrivacyPolicy')}
                </Link>
              </span>
              <span>
                <span> | </span>
              </span>
              <span
                style={{
                  marginLeft: 10,
                  textDecorationLine: 'underline',
                  color: 'black',
                  fontSize: 13,
                }}>
                <Link
                  href={buildKanbanaRedirectUrl(language ?? 'en', '/terms')}
                  target="_blank"
                  style={{ color: 'black' }}>
                  {t('Premium.TermsService')}
                </Link>
              </span>
            </div>
          </div>
        </ModalContainer>
      </Modal>
      {cancelSub && (
        <Modal>
          <ModalContainer
            style={{
              backgroundColor: '#eaeaea',
              height: 150,
              width: 300,
              borderRadius: 10,
            }}>
            <h3>Confirm Cancellation</h3>

            <span style={{ textAlign: 'center' }}>
              Do you really want to cancel your subscription?
            </span>
            <br></br>
            <div style={{ position: 'relative', width: '100%' }}>
              <span
                onClick={() => cancelSubscription()}
                style={{
                  color: 'white',
                  position: 'absolute',
                  left: '20%',
                  cursor: 'pointer',
                  height: 20,
                  width: 75,
                  border: '1px solid grey',
                  borderRadius: 5,
                  textAlign: 'center',
                  backgroundColor: '#f66e00',
                }}>
                <span>Confirm</span>
              </span>

              <span
                style={{
                  color: 'grey',
                  position: 'absolute',
                  right: '20%',
                  cursor: 'pointer',
                  height: 20,
                  width: 75,
                  border: '1px solid grey',
                  borderRadius: 5,
                  textAlign: 'center',
                }}
                onClick={() => hideAccount()}>
                <span>Not now</span>
              </span>
            </div>
          </ModalContainer>
        </Modal>
      )}
      {alertSub && (
        <Modal>
          <ModalContainer
            style={{
              backgroundColor: '#eaeaea',
              height: 250,
              width: 300,
              borderRadius: 10,
            }}>
            <h3>Upgrade</h3>

            <span style={{ padding: 10 }}>
              Your new plan will be activated as soon as the current one
              expires. With the yearly plan, you'll also avoid the hassle of
              monthly payments and get access to all our premium features
              throughout the year.
            </span>
            <br></br>
            <div style={{ position: 'relative', width: '100%' }}>
              <span>
                {[sub] &&
                  sub.map(subss => (
                    <span
                      onClick={() => handleClickUpgrade(subss?.Price_id)}
                      style={{
                        color: 'grey',
                        position: 'absolute',
                        left: '20%',
                        cursor: 'pointer',
                        height: 20,
                        width: 75,
                        border: '1px solid grey',
                        borderRadius: 5,
                        textAlign: 'center',
                      }}>
                      <span>Confirm</span>
                    </span>
                  ))}
              </span>

              <span
                style={{
                  color: 'white',
                  position: 'absolute',
                  right: '20%',
                  cursor: 'pointer',
                  height: 20,
                  width: 75,
                  border: '1px solid grey',
                  borderRadius: 5,
                  textAlign: 'center',
                  backgroundColor: '#f66e00',
                }}
                onClick={() => hideAccount()}>
                <span>Not now</span>
              </span>
            </div>
          </ModalContainer>
        </Modal>
      )}
    </>
  );
};

export const AlertSubModal: React.FC<Props> = ({
  onDismiss,
  IsKanbanaPremiumEnabled,
}) => {
  const { t } = useTranslation();
  const [subState, IsSubState] = useState(true);
  const [subVisible, setSubVisible] = useState(false);
  const [accountsVisible, setAccountVisible] = useState(false);
  useEffect(() => {
    let accountInfo: any = localStorage.getItem('AccountInfo');
    let subcode = localStorage.getItem('AccountInfo.subcode');
    let Id = localStorage.getItem('AccountInfo.Id');

    const isLoggedin = localStorage.getItem(
      'AccountInfo.IsKanbanaPremiumEnabled',
    );
    if (isLoggedin) {
      IsSubState(true);
      IsKanbanaPremiumEnabled = false;
    } else if (!isLoggedin) {
      IsSubState(false);
      IsKanbanaPremiumEnabled = false;
    }
  }, []);

  const handleKeyDown = useCallback(
    (e: any) => {
      e.stopPropagation();
      if (e.key === 'Escape') {
        onDismiss();
      }
    },
    [onDismiss],
  );
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  });

  const hideAccount = () => {
    setSubVisible(false);
    setAccountVisible(false);

    onDismiss();
  };
  const Model = () => {
    let data: any = localStorage.getItem('AccountInfo');
    const accountInfo = JSON.parse(data);
    let account: any = localStorage.getItem('accountId');

    const accountData = JSON.parse(account);
    if (accountInfo == null || accountData == null) {
      window.location.reload();
    } else {
      setSubVisible(true);
      setAccountVisible(false);
    }
  };

  return (
    <>
      <Modal>
        <ModalContainer
          style={{
            backgroundColor: '#eaeaea',
            width: 330,
            borderRadius: 10,
            padding: 0,
            textAlign: 'center',
          }}>
          <span
            style={{
              paddingRight: 10,
              paddingLeft: 10,
              paddingTop: 15,
              textAlign: 'center',
              fontSize: 14,
              marginBottom: -14,
            }}>
            {t('Alert.FreeCardLimitL1')}
          </span>
          <br></br>
          <span
            style={{
              paddingRight: 10,
              paddingLeft: 10,
              paddingTop: 15,
              textAlign: 'center',
              fontSize: 14,
              marginBottom: -14,
            }}>
            {t('Alert.FreeCardLimitL2')}
          </span>
          <br></br>
          <span
            style={{
              paddingRight: 10,
              paddingLeft: 10,
              paddingTop: 15,
              textAlign: 'center',
              fontSize: 14,
              marginBottom: -14,
            }}>
            {t('Alert.FreeCardLimitL3')}
          </span>
          <br></br>
          <hr
            style={{
              background: 'black',
              color: 'black',
              borderColor: 'black',
              height: 'black',
              width: 328,
              marginBottom: 0,
            }}
          />
          <div style={{ display: 'flex', width: '100%', height: 43 }}>
            <div
              onClick={Model}
              style={{
                color: 'blue',
                width: '50%',
                cursor: 'pointer',
                borderRight: '2px solid #000',
                textAlign: 'center',
                padding: 10,
              }}>
              Yes
            </div>

            <div
              style={{
                color: 'blue',
                width: '50%',
                cursor: 'pointer',
                textAlign: 'center',
                padding: 10,
              }}
              onClick={onDismiss}>
              Cancel
            </div>
          </div>
        </ModalContainer>
      </Modal>
      {subVisible && (
        <SubModal
          onDismiss={hideAccount}
          CheckoutForm={function (): void {
            throw new Error('Function not implemented.');
          }}
          App={function (): void {
            throw new Error('Function not implemented.');
          }}
        />
      )}
      {accountsVisible && (
        <AlertSubModal
          onDismiss={hideAccount}
          CheckoutForm={function (): void {
            throw new Error('Function not implemented.');
          }}
          App={function (): void {
            throw new Error('Function not implemented.');
          }}
        />
      )}
    </>
  );
};
