import React from 'react';
import { Button, Divider, Modal } from "antd";
import { useTranslation } from 'react-i18next';
import { TEMPLATES } from '../utils/template';

type Props = {
  open: boolean,
  onCancel: () => void,
  selectTemplate: (template: string) => void,
  onAddBoardClick: () => void
}

export const TemplateModal = ({ open, onCancel, selectTemplate, onAddBoardClick }: Props) => {
  const { t } = useTranslation();

  return <Modal onCancel={onCancel} open={open} footer={false} title={false}>
    <h1 style={{ textAlign: 'center' }}>{t('Template.Choose')}</h1>
    <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
      {TEMPLATES.map(x => <Button onClick={() => selectTemplate(x.id)} size='large'>{t(`Template.${x.id}`)}</Button>)}
      <Divider>{t('Template.OrContinueWith')}</Divider>
      <Button onClick={() => {
        onCancel();
        onAddBoardClick();
      }} size='large'>{t('Template.CreateOwn')}</Button>
    </div>
  </Modal>
}