import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import * as AsyncService from '../api/AsyncService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUserTimes,
  faArrowRight,
  faBolt,
  faFile,
  faCopy,
  faCheckCircle,
  faDotCircle,
  faCircleCheck,
  faArrowRightFromBracket,
} from '@fortawesome/free-solid-svg-icons';
import Spinner from '../resources/Spinner';
import { ProgressBar } from '../components/ProgressBar';
import { IDBPDatabase } from 'idb';
import { Database } from '../typings/database';
import {
  UPDATE_BOARD_LOCALLY,
  UPDATE_STAGE_LOCALLY,
  UPDATE_STORY_LOCALLY,
  getDataToBeRestored,
  useStoreContext,
  Synchronize,
} from '../store';
import { Board } from '../typings/board';
import { Stage } from '../typings/stage';
import { Story } from '../typings/story';
import { Trigger } from '../typings/synchronize';
import { PremiumModal } from '../components/PremiumModal';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
import { Plan, freePlan } from '../typings/plan';
import { addFastSpringCouponCode, addName, canCancelSubscription, expiredPlanAt, getAccountPlan, getEnableEmailNotification, getFastSpringCurrentPlan, getName, hasFastSpringCouponCode, isCancelledPlan, isFastSpringPremiumUser, isFreeAccount, setEnableEmailNotification } from '../utils/users';
import { FastSpringContext } from '../store/FastSpringContext';
import { getAuthenticationIcon, getUserProviderIdTranslation } from '../utils/provider';
import dayjs from 'dayjs';
import { Input, Popconfirm, Tabs, Spin, notification, Switch } from 'antd';
import Icon from '@ant-design/icons/lib/components/Icon';
import service from '../api/Service';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '..';
import { ResetPasswordModal } from './ResetPasswordModal';
import { isEmpty, isNil } from 'lodash';
import { CheckOutlined, SmileOutlined, DislikeOutlined } from '@ant-design/icons';

export type UserData = {
  uid: string;
  email: string;
  providerId: string;
};

const fetchUserData = async (): Promise<UserData> => {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      if (user) {
        resolve({
          uid: user?.uid ?? '',
          email: user?.email ?? '',
          providerId: user?.providerData[0]?.providerId ?? '',
        });
      } else {
        console.error('Error retrieving user data');
        reject();
      }
      // unsubscribe from the listener
      unsubscribe();
    });
  });
};

export type DeletedItem = {
  item: Board | Stage | Story;
  title: string;
  date: string;
};

const formatDate = (d?: Date): string => {
  if (!d) {
    return '';
  } else {
    const currentDate = new Date();
    const timeDifference = currentDate.getTime() - d.getTime();
    const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day

    if (timeDifference < oneDay) {
      return 'Today';
    } else if (timeDifference < 2 * oneDay) {
      return 'One day ago';
    } else {
      const daysAgo = Math.floor(timeDifference / oneDay);
      return `${daysAgo} days ago`;
    }
  }
};

const formatDeletedItems = (
  items: (Board | Stage | Story)[],
): DeletedItem[] => {
  return items.reduce<DeletedItem[]>((acc, cur) => {
    acc.push({
      item: cur,
      title: cur.title,
      date: formatDate(cur.deletedAt),
    });
    return acc;
  }, []);
};

const formatCompletedItems = (
  items: (Story)[],
): DeletedItem[] => {
  return items.reduce<DeletedItem[]>((acc, cur) => {
    acc.push({
      item: cur,
      title: cur.title,
      date: formatDate(cur.completedAt),
    });
    return acc;
  }, []);
};

const fetchCardColumnBoardData = async (
  accountId: string,
  database: IDBPDatabase<Database> | undefined,
): Promise<any> => {
  const itensToBeRestored = await getDataToBeRestored(accountId, database);
  return new Promise(async (resolve, reject) => {
    resolve({
      accountId,
      completedCards: formatCompletedItems(itensToBeRestored.completedCards),
      deletedCards: formatDeletedItems(itensToBeRestored.deletedCards),
      deletedColumns: formatDeletedItems(itensToBeRestored.deletedColumns),
      deletedBoards: formatDeletedItems(itensToBeRestored.deletedBoards),
    });
  });
};

export enum TAB_TITLE {
  MY_DETAILS = 'Account.MyDetails',
  PLANS = 'Account.Plans',
  RESTORE_DATA = 'Account.RestoreData',
  DELETE_ACCOUNT = 'Account.DeleteAccount',
}

interface Props {
  notifications: {
    [key in TAB_TITLE]?: number;
  };
  database?: IDBPDatabase<Database>;
  synchronize: Synchronize;
  onSignOutClick: () => void;
}

export interface AccountInfo {
  BoardId: string;
  CreatedAt: string;
  DeletedAt: string;
  UpdatedAt: string;
  Id: string;
  subcode: string;

  IsGuestUser: boolean;
  IsKanbanaPremiumEnabled: boolean;
  IsMultipleBoardsEnabled: boolean;
  IsStripeEnabled: boolean;
  IsSynchronizationEnabled: boolean;
  story: number;
  FastSpringCouponCode: string;
}

const MyDetailsTab = (props: {
  accountId?: string;
  onSignOutClick: () => void;
}) => {
  const { state } = useStoreContext();

  const { accountId, onSignOutClick } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [coupon, setCoupon] = useState('');
  const [providerId, setProviderId] = useState('');
  const [resetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);
  const [loadingValidation, setLoadingValidation] = useState(false);
  const [invalidCoupon, setInvalidCoupon] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [couponApplied, setCouponApplied] = useState(false);
  const [emailNotification, setEmailNotification] = useState(getEnableEmailNotification());
  const [loadingEmailNotification, setLoadingEmailNotification] = useState(false);
  const [loadingName, setLoadingName] = useState(false);

  const openNotification = (message: string, description: string, icon: any) => {
    api.open({
      message: message,
      description: description,
      icon: icon,
    });
  };

  useEffect(() => {
    if (!accountId) {
      setError('Please Log In');
      return;
    }

    (async () => {
      setLoading(true);

      const userData = await fetchUserData().catch(() => {
        setError('Could not load user data');
      });

      if (!userData) {
        setLoading(false);
        return;
      }
      setEmail(userData.email);
      setProviderId(userData.providerId);
      setName(getName());

      setLoading(false);

    })();
  }, [accountId]);

  const onValidateNameUpdateCallback = (success: boolean) => {
    if (success === false) {
      openNotification(
        t('Account.NameToast_Title'),
        t('Account.Name_Toast_Message_Error'),
        <DislikeOutlined style={{ color: 'red' }} />
      );
    } else {
      addName(name);
      openNotification(
        t('Account.NameToast_Title'),
        t('Account.Name_Toast_Message_Success'),
        <SmileOutlined style={{ color: '#108ee9' }} />
      );
    }
    setLoadingName(false);
  }

  const onNameChange = async () => {
    setLoadingName(true);
    await service.updateName(accountId ?? '', name, onValidateNameUpdateCallback)
  }

  const onResetPasswordClick = () => {
    setResetPasswordModalOpen(true);
  };

  const onValidateUserCouponCallback = (success: boolean) => {
    if (success === false) {
      setInvalidCoupon(true)
    } else {
      addFastSpringCouponCode(coupon);
      openNotification(
        t('Account.CouponApplied_Toast_Title'),
        t('Account.CouponApplied_Toast_Message'),
        <SmileOutlined style={{ color: '#108ee9' }} />
      );
      setCouponApplied(true);
    }
    setLoadingValidation(false);
  }

  const validateUserCouponCode = async () => {
    setLoadingValidation(true);
    await service.validateFastSpringCoupon(accountId ?? '', coupon, onValidateUserCouponCallback)
  }

  const onChangeEmailNotificationCallback = (success: boolean, value: boolean) => {
    if (success) {
      setEmailNotification(value);
      setEnableEmailNotification(value);
    }
    setLoadingEmailNotification(false);
  }

  const changeEmailNotification = async (value: boolean) => {
    setLoadingEmailNotification(true);
    await service.changeEmailNotification(value, onChangeEmailNotificationCallback, accountId)
  }

  const suffix = (action: any) => (
    <CheckOutlined
      style={{
        fontSize: 16,
        color: '#1677ff',
      }}
      onClick={action}
    />
  );

  return (
    <div className="tab-content">
      {contextHolder}
      {loading ? (
        <div className="tab-overlay">
          <Spinner />
        </div>
      ) : error ? (
        <div className="tab-overlay">{error}</div>
      ) : (
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <div className="tab-content-subheader">
                {t('Account.MyAccount')}
              </div>
            </div>
            <div className="button-container">
              <button className="btn primary" onClick={onSignOutClick}>
                <span>{t('Alert.SignOut')}</span>
                <FontAwesomeIcon
                  icon={faArrowRightFromBracket}
                  size="1x"
                  color="#fff"
                  style={{ fontSize: '20px', marginLeft: '8px' }}
                />
              </button>
            </div>
          </div>
          <hr />
          <div style={{ display: 'flex' }}>
            <div
              className="bold"
              style={{ width: '280px', marginRight: '32px' }}>
              {t('Account.Name')}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <span style={{ fontWeight: '600' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Input
                    placeholder={t('Account.NamePlaceholder') ?? undefined}
                    disabled={loadingName}
                    suffix={loadingName ? <Spin /> : suffix(onNameChange)}
                    style={{ width: 250 }}
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value)
                    }}
                  />
                </div>
              </span>
            </div>
          </div>
          <hr />
          <div style={{ display: 'flex' }}>
            <div
              className="bold"
              style={{ width: '280px', marginRight: '32px' }}>
              {t('Account.Email')}
            </div>
            <div>
              <span style={{ fontWeight: '600' }}>{email}</span>
            </div>
          </div>
          <hr />
          <div style={{ display: 'flex' }}>
            <div
              className="bold"
              style={{ width: '280px', marginRight: '32px' }}>
              {t('Account.LoginWith')}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              {getAuthenticationIcon(providerId)}
              <span style={{ fontWeight: '600', marginLeft: '10px' }}>
                {getUserProviderIdTranslation(providerId)}
              </span>
            </div>
            <div
              style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
              <button className="btn primary" onClick={onResetPasswordClick}>
                {t('Account.ResetPassword')}
              </button>
            </div>
          </div>
          <hr />
          <div style={{ display: 'flex' }}>
            <div
              className="bold"
              style={{ width: '280px', marginRight: '32px' }}>
              {t('Account.LastSynchronized')}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <span style={{ fontWeight: '600' }}>
                {dayjs(state.lastSynchronizedAt).format(
                  'MMM DD, YYYY - HH:mm:ss',
                )}
              </span>
            </div>
          </div>
          <hr />
          <div style={{ display: 'flex' }}>
            <div
              className="bold"
              style={{ width: '280px', marginRight: '32px' }}>
              {t('Account.EmailNotification')}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <Switch onChange={(value) => changeEmailNotification(value)} value={emailNotification} loading={loadingEmailNotification} />
            </div>
          </div>
          {isFreeAccount() && (
            <>
              <hr />
              <div style={{ display: 'flex' }}>
                <div
                  className="bold"
                  style={{ width: '280px', marginRight: '32px' }}>
                  {t('Account.CouponCode')}
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  <span style={{ fontWeight: '600' }}>
                    {
                      (!hasFastSpringCouponCode() && !couponApplied) ?
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <Input
                            placeholder={t('Account.CouponCodePlaceholder') ?? undefined}
                            disabled={loadingValidation}
                            suffix={loadingValidation ? <Spin /> : suffix(validateUserCouponCode)}
                            style={{ width: 250 }}
                            value={coupon}
                            onChange={(e) => {
                              setCoupon(e.target.value)
                              setInvalidCoupon(false);
                            }}
                          />
                          {invalidCoupon &&
                            <span style={{ fontWeight: '600', marginTop: 5, color: 'red' }}>{t('Account.CouponInvalid')}</span>
                          }
                        </div> :
                        <span style={{ fontWeight: '600' }}>{t('Account.HasValidCoupon')}</span>
                    }
                  </span>
                </div>
              </div>
            </>
          )}
        </>
      )}
      <div className='modal' />
      <ResetPasswordModal
        open={resetPasswordModalOpen}
        onDismiss={() => setResetPasswordModalOpen(false)}
        onSubmit={() => setResetPasswordModalOpen(false)}
      />
    </div>
  );
};

const RestoreDataTab = (props: {
  accountId?: string;
  database?: IDBPDatabase<Database>;
  synchronize: Synchronize;
}) => {
  const { t } = useTranslation();
  const { accountId, database, synchronize } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [deletedCards, setDeletedCards] = useState<
    { title: string; date: string; item: Story }[]
  >([]);
  const [deletedColumns, setDeletedColumns] = useState<
    { title: string; date: string; item: Stage }[]
  >([]);
  const [deletedBoards, setDeletedBoards] = useState<
    { title: string; date: string; item: Board }[]
  >([]);
  const [completedCards, setCompletedCards] = useState<
    { title: string; date: string; item: Story }[]
  >([]);

  useEffect(() => {
    if (!accountId) {
      setError('Please Log In');
      return;
    }

    (async () => {
      setLoading(true);

      await synchronize({
        accountId,
        trigger: Trigger.User,
        pusher: 3,
      });

      const cardColumnBoardData = await fetchCardColumnBoardData(
        accountId,
        database,
      ).catch(() => {
        setError('Could not load user data');
      });

      setLoading(false);
      if (!cardColumnBoardData) {
        return;
      }
      setDeletedCards(cardColumnBoardData.deletedCards);
      setDeletedColumns(cardColumnBoardData.deletedColumns);
      setDeletedBoards(cardColumnBoardData.deletedBoards);
      setCompletedCards(cardColumnBoardData.completedCards);
    })();
  }, [accountId]);

  return (
    // <div className='mock-tab-content'>TODO: Restore Deleted Data Tab</div>
    <div className="tab-content">
      {loading ? (
        <div className="tab-overlay">
          <Spinner />
        </div>
      ) : error ? (
        <div className="tab-overlay">{error}</div>
      ) : (
        <div style={{ flex: 1 }}>
          <div style={{ marginRight: '32px', paddingBottom: 32 }}>
            <div className="bold">{t('Account.Restore')}</div>
            <div>{t('Account.ShareSnippets')}</div>
          </div>
          <div style={{ display: 'flex' }}>
            <Tabs size='large' type='card' tabPosition="left"
              items={[
                {
                  key: 'Restore.CompletedCards',
                  label: t('Restore.CompletedCards'),
                  children: !isEmpty(completedCards) ? completedCards.map((item, index) => (
                    <Card
                      key={index}
                      title={item.title}
                      date={item.date}
                      item={item.item}
                    />
                  )) : <h1>{t('Restore.NoCardsToBeRestored')}</h1>
                },
                {
                  key: 'Restore.DeletedCards',
                  label: t('Restore.DeletedCards'),
                  children: !isEmpty(deletedCards) ? deletedCards.map((item, index) => (
                    <Card
                      key={index}
                      title={item.title}
                      date={item.date}
                      item={item.item}
                    />
                  )) : <h1>{t('Restore.NoCardsToBeRestored')}</h1>
                },
                {
                  key: 'Restore.DeletedColumns',
                  label: t('Restore.DeletedColumns'),
                  children: !isEmpty(deletedColumns) ? deletedColumns.map((item, index) => (
                    <Card
                      key={index}
                      title={item.title}
                      date={item.date}
                      item={item.item}
                    />
                  )) : <h1>{t('Restore.NoListToBeRestored')}</h1>
                },
                {
                  key: 'Restore.DeletedBoards',
                  label: t('Restore.DeletedBoards'),
                  children: !isEmpty(deletedBoards) ? deletedBoards.map((item, index) => (
                    <Card
                      key={index}
                      title={item.title}
                      date={item.date}
                      item={item.item}
                    />
                  )) : <h1>{t('Restore.NoBoardsToBeRestored')}</h1>
                }
              ]} />
          </div>
        </div>
      )}
    </div>
  );
};
// Card component
interface CardProps {
  item: Board | Story | Stage;
  title: string;
  date: string;
}
function Card({ item, title, date }: CardProps) {
  const { dispatch } = useStoreContext();
  const [isRestored, setIsRestored] = useState(false);
  const [showRestoredLabel, setShowRestoredLabel] = useState(false);
  const restoreItem = async (item: Board | Story | Stage) => {
    if (isRestored) {
      return;
    }
    if ('stageId' in item) {
      dispatch({
        payload: {
          ...item,
          deletedAt: null,
          completedAt: null,
          updatedAt: new Date(),
          priority: 999
        },
        type: UPDATE_STORY_LOCALLY,
      });
      ReactGA.event({
        category: 'task',
        action: 'taskUpdated',
      });
    } else if ('boardId' in item) {
      dispatch({
        payload: {
          ...item,
          deletedAt: null,
          updatedAt: new Date(),
          priority: 999
        },
        type: UPDATE_STAGE_LOCALLY,
      });
      ReactGA.event({
        category: 'stage',
        action: 'stageUpdated',
      });
    } else {
      dispatch({
        payload: {
          ...item,
          deletedAt: null,
          priority: 1,
          updatedAt: new Date(),
        },
        type: UPDATE_BOARD_LOCALLY,
      });
      ReactGA.event({
        category: 'board',
        action: 'boardUpdated',
      });
    }
    setIsRestored(true);
    const LABEL_DELAY = 300;
    await new Promise(resolve =>
      setTimeout(() => {
        resolve(null);
      }, LABEL_DELAY),
    );
    setShowRestoredLabel(true);
  };
  return (
    <div
      className={`deleted-card ${isRestored ? 'restored-card' : ''}`}
      onClick={e => restoreItem(item)}>
      <div className="deleted-card-left">
        <div className="deleted-card-title">{title}</div>
        {showRestoredLabel && (
          <div className="restored-card-label">{t('Restore.Restored')}</div>
        )}
      </div>
      {isRestored ? (
        <div className="restored-card-check">
          <FontAwesomeIcon
            icon={faCircleCheck}
            color="#01B6E9"
            style={{ fontSize: '16px' }}
          />
        </div>
      ) : (
        <div className="deleted-card-date">{date}</div>
      )}
    </div>
  );
}

const DeleteAccountTab = (props: {
  accountId?: string;
  onConfirm: () => void;
}) => {
  const { accountId } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const handleConfirm = async () => {
    await props.onConfirm();
    closeModal();
  };

  useEffect(() => {
    if (!accountId) {
      setError('Please Log In');
      return;
    }

    (async () => {
      setLoading(false);
    })();
  }, [accountId]);

  return (
    <div className="tab-content">
      {loading ? (
        <div className="tab-overlay">
          <Spinner />
        </div>
      ) : error ? (
        <div className="tab-overlay">{error}</div>
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              width: '100%',
              borderRadius: '12px',
              boxShadow: '0px 1px 2px 0px #1018280F,0px 1px 3px 0px #1018281A',
              background: 'white',
            }}>
            <div
              style={{
                display: 'flex',
                padding: '24px 0px 24px 24px',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <FontAwesomeIcon icon={faUserTimes} size="8x" color="#3D3D3D" />
            </div>
            <div style={{ flex: '1', padding: '24px', width: '884px' }}>
              <div className="bold" style={{ fontSize: '18px' }}>
                {t('Alert.DoYouWantToDeleteAccountAndData')}
              </div>
              <div style={{ fontSize: '14px', marginTop: '6px' }}>
                {t('Alert.DeleteDataDescprition')}
              </div>
              <div
                style={{
                  width: '264px',
                  height: '40px',
                  gap: '12px',
                  marginTop: '24px',
                  display: 'flex',
                }}>
                <button className="btn" style={{ marginRight: '12px' }}>
                  {t('Text.Dismiss')}
                </button>
                <button
                  style={{ minWidth: 164, maxWidth: '100%' }}
                  className="btn primary"
                  onClick={openModal}>
                  {t('Account.DeleteMyAccount')}
                </button>
              </div>
            </div>
          </div>
          {isOpen && (
            <div className="delete-account-modal">
              <div className="delete-account-modal-content">
                <h2>{t('Alert.DoYouWantToDeleteData')}</h2>
                <p>{t('Alert.CannotBeReverted')}</p>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}>
                  <button
                    className="btn"
                    style={{ marginRight: '12px' }}
                    onClick={closeModal}>
                    {t('Text.Cancel')}
                  </button>
                  <button className="btn primary" onClick={handleConfirm}>
                    {t('Account.DeleteMyAccount')}
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

const getPlanDescription = (plan: string) => {
  if (plan.startsWith("FASTSPRING")) return "Premium.PaymentMode.Web";
  if (plan.startsWith("IOS")) return "Premium.PaymentMode.IOS"
  if (plan.startsWith("ANDROID")) return "Premium.PaymentMode.Android"
  return '';
}

const PlansTab = (props: { accountId?: string; stories?: number }) => {
  const { accountId, stories } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [userPlan, setUserPlan] = useState<Plan>(freePlan);
  const [showPremiumModal, setShowPremiumModal] = useState(false);
  const [userPlans, setUserPlans] = useState([]);

  const { plans } = React.useContext(FastSpringContext);

  useEffect(() => {
    (async () => {
      const userFastSpringPlans = await service.getFastSpringUserPlans(accountId ?? '');
      const fastSpringPlans = plans.filter((plan: any) => userFastSpringPlans?.plansIds.includes(plan.pid));
      setUserPlans(fastSpringPlans);
      setLoading(false);
    })();
  }, [plans])

  const cancelSubscriptionHandleClick = async () => {
    setLoading(true);
    service.cancelSubscription(accountId ?? '', () => setLoading(false));
  }

  const upgradeSubscriptionHandleClick = async () => {
    setLoading(true);
    service.upgradeSubscription(accountId ?? '', () => setLoading(false));
  }

  return (
    <div className="tab-content">
      {loading ? (
        <div className="tab-overlay">
          <Spinner />
        </div>
      ) : error ? (
        <div className="tab-overlay">{error}</div>
      ) : (
        <>
          <div className="current-plan-card">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <div className="tab-content-subheader">
                  {t('Premium.CurrentPlan')}
                </div>
                {isFreeAccount() && <div>{t('Premium.LimitedExperience')}</div>}
                {!isFreeAccount() && <div>{t('Premium.UnlimitedExperience')}</div>}
              </div>
              <div>
                <div className="plan-cost" style={{ fontSize: isFreeAccount() ? undefined : '16px', display: 'flex', justifyContent: 'flex-end' }}>
                  {isFreeAccount()
                    ? t('Account.Free')
                    : t('Premium.KanbanaPremium')}
                </div>

                {canCancelSubscription() &&
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Popconfirm
                      placement="left"
                      onConfirm={cancelSubscriptionHandleClick}
                      title={t('Text.Cancel.Confirmation')}
                      icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                    >
                      <a style={{ textDecoration: 'underline', marginTop: 10, cursor: 'pointer' }} href="#">{t('Text.Cancel')}</a>
                    </Popconfirm>
                  </div>
                }

                {isCancelledPlan() &&
                  <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <span style={{ marginTop: 10, color: 'red' }} >{(t('Account.Plans.SubscriptionCancelled.Message'))} {expiredPlanAt()}</span>
                  </div>
                }

              </div>
            </div>
            {!isFreeAccount() && getAccountPlan() !== "" && (
              <>
                <hr />
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <div className="tab-content-subheader">
                      {t('Premium.PaymentMode')}
                    </div>
                    {getPlanDescription(getAccountPlan()) !== '' && <div>{t(getPlanDescription(getAccountPlan()))}</div>}
                  </div>
                </div>
              </>
            )}
            {isFreeAccount() && (
              <div className="progress-bar-container">
                <div>
                  {stories} of{' '}
                  {userPlan.Id === 'FREE' ? '20' : t('Text.Unlimited')}{' '}
                  {t('Text.Tasks')}
                </div>
                <ProgressBar
                  val={stories || 0}
                  maxVal={20}
                  style={{ marginTop: '12px' }}
                />
              </div>)}
            {isFreeAccount() && (
              <>
                <hr />
                <div className="d-flex justify-content-end">
                  <div
                    className="upgrade-plan-button"
                    onClick={() => {
                      setShowPremiumModal(true);
                    }}>
                    {t('Account.UpgradePlan')}
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      style={{ marginLeft: '5px' }}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
          {
            isFastSpringPremiumUser() && !isCancelledPlan() &&
            !isEmpty(userPlans) &&
            <div className="plan-selector-container">
              <div>
                <div>
                  <b>{t('Premium.CurrentPlan')}</b>
                </div>
              </div>

              <div style={{ flexGrow: 1 }}>
                {userPlans.map((product: any) => {
                  const isYearly = product.display.includes("Yearly");
                  const isFastSpringYearly = getFastSpringCurrentPlan() === "YEARLY";
                  const isCurrentPlan = (isYearly && isFastSpringYearly) || (!isYearly && !isFastSpringYearly);
                  return (
                    <div
                      style={{
                        cursor: 'default',
                        border: isCurrentPlan ? '2px solid var(--picton-blue-500, #01B6E9)' : '1px solid var(--gray-200, #EAECF0)'
                      }}
                      className={`plan-card`}
                      key={isYearly ? "Yearly" : "Monthly"}>
                      <div className="plan-icon-container">
                        {
                          !isYearly ? (
                            <FontAwesomeIcon icon={faCopy} />
                          ) : isYearly ? (
                            <FontAwesomeIcon icon={faBolt} />
                          ) : (
                            <FontAwesomeIcon icon={faFile} />
                          ) //default
                        }
                      </div>
                      <div style={{ flexGrow: 1 }}>
                        <b>
                          {
                            !isYearly
                              ? t('Account.MonthlyPlan')
                              : isYearly
                                ? t('Account.YearlyPlan')
                                : ''}
                        </b>{' '}
                        {<span>{product?.future?.total ?? product.total}</span>}
                        {isYearly ? (
                          <div>{t('Premium.EverythingIncluded1')}</div>
                        ) : !isYearly ? (
                          <div>{t('Premium.EverythingIncluded2')}</div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="plan-checked">
                        {
                          isYearly && !isCurrentPlan ? (
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                              <Popconfirm
                                placement="left"
                                onConfirm={upgradeSubscriptionHandleClick}
                                title={t('Text.Upgrade.Confirmation')}
                                icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                              >
                                <a style={{ textDecoration: 'underline', marginTop: 10, cursor: 'pointer' }} href="#">{t('Account.UpgradePlan')}</a>
                              </Popconfirm>
                            </div>) :
                            <FontAwesomeIcon icon={faDotCircle} />
                        }
                      </div>
                    </div>
                  )
                })
                }
              </div>
            </div>

          }
          {isFreeAccount() && <>
            <div className="plan-selector-title">
              <div>
                <div className="tab-content-subheader">
                  {t('Premium.AccountPlans')}
                </div>
                <div>{t('Premium.PickAPlan')}</div>
              </div>
            </div>

            <hr />

            <div className="plan-selector-container">
              <div>
                <div>
                  <b>{t('Premium.CurrentPlan')}</b>
                </div>
              </div>

              <div style={{ flexGrow: 1 }}>
                <div
                  style={{
                    cursor: 'default',
                  }}
                  onClick={() => {
                    // setShowPremiumModal(true);
                  }}
                  className={`plan-card selected`}
                  key={"free"}>
                  <div className="plan-icon-container">
                    <FontAwesomeIcon icon={faFile} />
                  </div>
                  <div style={{ flexGrow: 1 }}>
                    <b>{t('Account.FreePlan')}
                    </b>{' '}
                    <div>
                      <div>
                        {stories} {t('Premium.OutOf')} 20 {t('Text.Tasks')}
                      </div>
                      <ProgressBar val={stories || 0} maxVal={20} />
                    </div>
                  </div>
                  <div className="plan-checked">
                    <FontAwesomeIcon icon={faCheckCircle} color="#01B6E9" />
                  </div>
                </div>
                {userPlans.reverse().map((product: any) => {
                  const isYearly = product.display.includes("Yearly");
                  return (
                    <div
                      style={{
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setShowPremiumModal(true);
                      }}
                      className={`plan-card`}
                      key={isYearly ? "Yearly" : "Monthly"}>
                      <div className="plan-icon-container">
                        {
                          !isYearly ? (
                            <FontAwesomeIcon icon={faCopy} />
                          ) : isYearly ? (
                            <FontAwesomeIcon icon={faBolt} />
                          ) : (
                            <FontAwesomeIcon icon={faFile} />
                          ) //default
                        }
                      </div>
                      <div style={{ flexGrow: 1 }}>
                        <b>
                          {
                            !isYearly
                              ? t('Account.MonthlyPlan')
                              : isYearly
                                ? t('Account.YearlyPlan')
                                : ''}
                        </b>{' '}
                        {<span>{product?.future?.total ?? product.total}</span>}
                        {isYearly ? (
                          <div>{t('Premium.EverythingIncluded1')}</div>
                        ) : !isYearly ? (
                          <div>{t('Premium.EverythingIncluded2')}</div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="plan-checked">
                        <FontAwesomeIcon icon={faDotCircle} />
                      </div>
                    </div>
                  )
                })
                }
              </div>
            </div>
          </>}
          {showPremiumModal && (
            <PremiumModal
              hidePremiumModal={() => {
                setShowPremiumModal(false);
              }}
            />
          )}
        </>
      )
      }
    </div >
  );
};

export const AccountPanel = (props: Props) => {
  const { notifications } = props;
  const [selectedTab, setSelectedTab] = useState<TAB_TITLE>(
    TAB_TITLE.MY_DETAILS,
  );
  const accountInfoRaw: string | null = localStorage.getItem('AccountInfo');
  const accountInfo: AccountInfo | undefined = accountInfoRaw
    ? JSON.parse(accountInfoRaw)
    : undefined;
  const storiesRaw: string | null = localStorage.getItem('stories');
  const stories: number | undefined = storiesRaw
    ? JSON.parse(storiesRaw)
    : undefined;
  const handleDeleteAccount = async () => {
    const deleteResponse = await AsyncService.deleteAccount(
      accountInfo?.Id ?? '',
    );
    props.onSignOutClick();
  };

  return (
    <div className="account-panel-content">
      <div className="header">{t('Account.MyAccount')}</div>
      <div className="subheader">{t('Account.ManageYourAccount')}</div>

      <div className="tab-container">
        <div className="tab-header unselectable">
          {Object.values(TAB_TITLE).map(title => (
            <div
              key={title}
              className={`tab-title ${title === selectedTab ? 'selected' : ''}`}
              onClick={() => {
                setSelectedTab(title);
              }}>
              {t(title)}
              {notifications[title] && (
                <span className="tab-notification-count">
                  {notifications[title]}
                </span>
              )}
            </div>
          ))}
        </div>

        <div className="tab-content">
          {(() => {
            switch (selectedTab) {
              case TAB_TITLE.MY_DETAILS:
                return (
                  <MyDetailsTab
                    accountId={accountInfo?.Id}
                    onSignOutClick={props.onSignOutClick}
                  />
                );
              case TAB_TITLE.RESTORE_DATA:
                return (
                  <RestoreDataTab
                    accountId={accountInfo?.Id}
                    database={props.database}
                    synchronize={props.synchronize}
                  />
                );
              case TAB_TITLE.DELETE_ACCOUNT:
                return (
                  <DeleteAccountTab
                    accountId={accountInfo?.Id}
                    onConfirm={handleDeleteAccount}
                  />
                );
              case TAB_TITLE.PLANS:
                return (
                  <PlansTab accountId={accountInfo?.Id} stories={stories} />
                );
            }
          })()}
        </div>
      </div>
    </div>
  );
};
