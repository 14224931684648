import React, { PropsWithChildren, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { Modal as ModalAntd } from 'antd';

type Props = PropsWithChildren<{}>;

const Modal: React.FC<Props> = ({ children }) => {
  const elRef = useRef<HTMLDivElement>();
  if (!elRef.current) {
    const div = document.createElement('div');
    elRef.current = div;
  }

  useEffect(() => {
    const modalRoot = document.getElementById('modal');
    if (!modalRoot) {
      return;
    }

    modalRoot.appendChild(elRef.current as Node);
    document.body.style.overflow = 'hidden';

    return () => {
      modalRoot.removeChild(elRef.current as Node);
      document.body.style.overflow = 'auto';
    };
  }, []);

  return createPortal(
    <ModalWrapper open={true} width={600} footer={false} closable={false}>
      {children}
    </ModalWrapper>,
    elRef.current,
  );
};
const ModalWrapper = styled(ModalAntd)`
  .ant-modal-content {
    padding: 0;
  }
`;

export default Modal;
