export enum BOARD_TEMPLATE_IDS {
  WORK_TEMPLATE = 'WORK_TEMPLATE',
  SCHOOL_TEMPLATE = 'SCHOLL_TEMPLATE',
  SHOPPING_TEMPLATE = 'SHOPPING_TEMPLATE',
  WEEKLY_SCHEDULE_TEMPLATE = 'WEEKLY_SCHEDULE_TEMPLATE',
  HOME_PROJECT = 'HOME_PROJECT',
}

export const TEMPLATES = [{
  id: 'Work',
  columns: [
    {
      boardId: BOARD_TEMPLATE_IDS.WORK_TEMPLATE,
      id: 'WORK_TEMPLATE_5',
      priority: 5,
      storyLimit: 12,
      title: 'Ideas',
    },
    {
      boardId: BOARD_TEMPLATE_IDS.WORK_TEMPLATE,
      id: 'WORK_TEMPLATE_4',
      priority: 4,
      storyLimit: 12,
      title: 'Goals',
    },
    {
      boardId: BOARD_TEMPLATE_IDS.WORK_TEMPLATE,
      id: 'WORK_TEMPLATE_3',
      priority: 3,
      storyLimit: 12,
      title: 'FollowUp',
    },
    {
      boardId: BOARD_TEMPLATE_IDS.WORK_TEMPLATE,
      id: 'WORK_TEMPLATE_2',
      priority: 2,
      storyLimit: 12,
      title: 'DoingToday',
    },
    {
      boardId: BOARD_TEMPLATE_IDS.WORK_TEMPLATE,
      id: 'WORK_TEMPLATE_1',
      priority: 1,
      storyLimit: 12,
      title: 'ToDoList',
    },
  ]
}, {
  id: 'WeeklyPlan',
  columns: [
    {
      boardId: BOARD_TEMPLATE_IDS.WEEKLY_SCHEDULE_TEMPLATE,
      id: 'WEEKLY_SCHEDULE_TEMPLATE_7',
      priority: 7,
      storyLimit: 12,
      title: 'Sunday',
    },
    {
      boardId: BOARD_TEMPLATE_IDS.WEEKLY_SCHEDULE_TEMPLATE,
      id: 'WEEKLY_SCHEDULE_TEMPLATE_6',
      priority: 6,
      storyLimit: 12,
      title: 'Saturday',
    },
    {
      boardId: BOARD_TEMPLATE_IDS.WEEKLY_SCHEDULE_TEMPLATE,
      id: 'WEEKLY_SCHEDULE_TEMPLATE_5',
      priority: 5,
      storyLimit: 12,
      title: 'Friday',
    },
    {
      boardId: BOARD_TEMPLATE_IDS.WEEKLY_SCHEDULE_TEMPLATE,
      id: 'WEEKLY_SCHEDULE_TEMPLATE_4',
      priority: 4,
      storyLimit: 12,
      title: 'Thursday',
    },
    {
      boardId: BOARD_TEMPLATE_IDS.WEEKLY_SCHEDULE_TEMPLATE,
      id: 'WEEKLY_SCHEDULE_TEMPLATE_3',
      priority: 3,
      storyLimit: 12,
      title: 'Wednesday',
    },
    {
      boardId: BOARD_TEMPLATE_IDS.WEEKLY_SCHEDULE_TEMPLATE,
      id: 'WEEKLY_SCHEDULE_TEMPLATE_2',
      priority: 2,
      storyLimit: 12,
      title: 'Tuesday',
    },
    {
      boardId: BOARD_TEMPLATE_IDS.WEEKLY_SCHEDULE_TEMPLATE,
      id: 'WEEKLY_SCHEDULE_TEMPLATE_1',
      priority: 1,
      storyLimit: 12,
      title: 'Monday',
    },
  ]
}, {
  id: 'ShoppingList',
  columns: [
    {
      boardId: BOARD_TEMPLATE_IDS.SHOPPING_TEMPLATE,
      id: 'SHOPPING_TEMPLATE_4',
      priority: 3,
      storyLimit: 12,
      title: 'Clothing',
    },
    {
      boardId: BOARD_TEMPLATE_IDS.SHOPPING_TEMPLATE,
      id: 'SHOPPING_TEMPLATE_3',
      priority: 2,
      storyLimit: 12,
      title: 'PetStore',
    },
    {
      boardId: BOARD_TEMPLATE_IDS.SHOPPING_TEMPLATE,
      id: 'SHOPPING_TEMPLATE_2',
      priority: 1,
      storyLimit: 12,
      title: 'Pharmacy',
    },
    {
      boardId: BOARD_TEMPLATE_IDS.SHOPPING_TEMPLATE,
      id: 'SHOPPING_TEMPLATE_1',
      priority: 0,
      storyLimit: 12,
      title: 'GroceryStore',
    },
  ]
},
{
  id: 'HomeProject',
  columns: [
    {
      boardId: BOARD_TEMPLATE_IDS.HOME_PROJECT,
      id: 'HOME_PROJECT_1',
      priority: 0,
      storyLimit: 12,
      title: 'Bedroom',
    },
    {
      boardId: BOARD_TEMPLATE_IDS.HOME_PROJECT,
      id: 'HOME_PROJECT_2',
      priority: 1,
      storyLimit: 12,
      title: 'Kitchen',
    },
    {
      boardId: BOARD_TEMPLATE_IDS.HOME_PROJECT,
      id: 'HOME_PROJECT_3',
      priority: 2,
      storyLimit: 12,
      title: 'LivingRoom',
    },
    {
      boardId: BOARD_TEMPLATE_IDS.HOME_PROJECT,
      id: 'HOME_PROJECT_4',
      priority: 3,
      storyLimit: 12,
      title: 'FamilyRoom',
    },
  ]
},
{
  id: 'School',
  columns: [
    {
      boardId: BOARD_TEMPLATE_IDS.SCHOOL_TEMPLATE,
      id: 'SCHOOL_TEMPLATE_1',
      priority: 0,
      storyLimit: 12,
      title: 'Math',
    },
    {
      boardId: BOARD_TEMPLATE_IDS.SCHOOL_TEMPLATE,
      id: 'SCHOOL_TEMPLATE_2',
      priority: 1,
      storyLimit: 12,
      title: 'Science',
    },
    {
      boardId: BOARD_TEMPLATE_IDS.SCHOOL_TEMPLATE,
      id: 'SCHOOL_TEMPLATE_3',
      priority: 2,
      storyLimit: 12,
      title: 'History',
    },
    {
      boardId: BOARD_TEMPLATE_IDS.SCHOOL_TEMPLATE,
      id: 'SCHOOL_TEMPLATE_4',
      priority: 3,
      storyLimit: 12,
      title: 'TemplateLanguage',
    },
  ]
},
];