import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import React from 'react';

type Props = {
  type: 'SignIn' | 'SignUp';
  children: any;
};

const TranslateAuth = ({ children, type }: Props) => {
  const { i18n } = useTranslation();

  useEffect(() => {
    const divObservada = document.getElementById('observer')!;
    const observer = new MutationObserver(function(mutationsList) {
      mutationsList.forEach(function(mutation) {
        const divFire = document.getElementById('observer');

        if (divFire) {
          const hasText = (node: any) => {
            return (
              node.nodeType === Node.TEXT_NODE && node.textContent.trim() !== ''
            );
          };

          const elWithText = Array.from(divFire.querySelectorAll('*')).filter(
            elemento => {
              const children = Array.from(elemento.childNodes);
              return children.some(filho => hasText(filho));
            },
          );

          elWithText.forEach(el => {
            if (
              i18n.exists(`Firebase.${type}.${el.innerHTML}`) &&
              i18n.t(`Firebase.${type}.${el.innerHTML}`) !== el.innerHTML
            )
              el.innerHTML = i18n.t(`Firebase.${type}.${el.innerHTML}`);
          });
        }
      });
    });
    observer.observe(divObservada, { childList: true, subtree: true });
  }, [i18n, type]);

  return <div id="observer">{children}</div>;
};

export default TranslateAuth;
