import { isNil } from 'lodash';
import { useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';
import { auth } from '..';

interface IntercomWidgetProps {
  userId?: string;
}

export const IntercomWidget = ({ userId }: IntercomWidgetProps) => {
  const { update, boot, shutdown } = useIntercom();
  useEffect(() => {
    if (!isNil(userId)) {
      const currentUser = auth.currentUser;
      const attr = {
        firebase_uid: currentUser?.uid,
        provider_id: !isNil(currentUser) && currentUser?.providerData?.length > 0
          ? currentUser?.providerData[0].providerId
          : '',
      }
      update({ userId, email: currentUser?.email ?? undefined, customAttributes: attr });
      setTimeout(() => {
        shutdown();
        boot(({ userId, email: currentUser?.email ?? undefined, customAttributes: attr }));
      }, 3000);
    } else {
      boot();
    }
  }, [userId]);

  return null;
}