import React, { MouseEvent, useEffect, useState } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';
import {
  faUserCog,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faXmark,
  faArrowRightFromBracket,
} from '@fortawesome/free-solid-svg-icons';
import { BoardList } from '../board/BoardList';
import Menu from '../menu/Menu';
import { BoardMenuItem } from '../menu/MenuViewModel';
import { Board, CopyBoardResponse } from '../typings/board';
import { Dictionary } from '../typings/utils';
import { MENU_ANIMATION_DURATION } from '../utils/constants';
import { SubModal } from '../Subscription/subsription';
import i18n from 'i18next';
//import SearchInput from '../components/SearchInput';
import downlogo from '../resources/images/board-chevron-down.png';
import uplogo from '../resources/images/board-chevron-up.png';
import imgLogo from '../resources/images/logo.png';
import imgLogoFull from '../resources/images/new_logo.png';
import imgHome from '../resources/images/home_icon.png';
import imgGettingStarted from '../resources/images/getting_started_icon.png';
import imgHelp from '../resources/images/help_icon.png';
import imgSync from '../resources/images/sync_icon.png';
import pointerClickIcon from '../resources/images/pointer_click_icon.png';
import { PremiumModal } from '../components/PremiumModal';
import { GetStartedModal } from '../components/GetStartedModal';
import { LanguageSelector } from '../components/LanguageSelector';
import { GetLanguageMetadata } from '../utils/lang';
import { Button, Dropdown, MenuProps } from 'antd';
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import { ArrowLeftOutlinedStyled, BoardCount, DropdownBoardButton, FlexBoard, TemplateTitle } from './Sidebar.styled';
import { isFreeAccount } from '../utils/users';
import { TemplateModal } from '../board/TemplateModal';
import { TEMPLATES } from '../utils/template';
import { CompletedCardsSelector } from '../components/CompletedCardsSelector';
import { Story } from '../typings/story';
import { PeriodCompletedCards } from '../board/ViewModels';
import { buildKanbanaRedirectUrl } from '../utils/externalLink';
import { useIntercom } from 'react-use-intercom';

export type Props = {
  accountId?: string;
  activeBoardId?: string;
  activeTemplateId?: string;
  addBoardModalVisible: boolean;
  boards: Board[];
  boardCount?: number;
  storyCount?: number;
  canCopyBoard?: boolean;
  webTrialEnds?: number;
  isBoldColors: boolean;
  isCompletedCards: boolean;
  selectedPeriodCompletedCards: PeriodCompletedCards;
  isMenuExpanded: boolean;
  handleShowMyAccountPanel: (value: boolean) => void,
  onAddBoardClick: () => void;
  onAddBoardsClick: () => void;
  onAddBoardTemplateClick: () => void;
  onBoardCopied: (response: CopyBoardResponse) => void;
  onBoardDelete: (boardId: string) => void;
  onBoardDragEnd: (result: DropResult) => void;
  onBoardSelect: (boardId: string) => void;
  onBoardSyncClick: () => Promise<void>;
  onBoardUpdate: (board: Board) => void;
  onMenuToggle: (value: boolean) => void;
  onSignOutClick: () => void;
  handleToggle: () => void;
  handleClickMyAccount: () => void;
  handleSetBackgroundFreezeState: (state: boolean) => void;
  t?: any;
  completedStories: Story[];
  handleChangePeriodCompletedCards: (value: PeriodCompletedCards) => void;
  handleToggleCompletedCards: () => void;
  onChangeLanguage: (lang: string) => void;
};
type SideBarProps = {
  canCloseModal: boolean,
  setGoPremiumModalVisible: (value: boolean) => void;
}

type State = {
  animating: boolean;
  accountsVisible: boolean;
  getStartedModalVisible: boolean;
  boardListVisible: boolean;
  boardMenuItems: BoardMenuItem[];
  isBoardsExpanded: boolean;
  isMenuVisible: boolean;
  imagesPreloaded: boolean;
  synchronizing: boolean;
  boardTitle: string;
  lang: string;
  langFlg: string;
  langFullName: string;
  editing: Dictionary<boolean>;
  tutorialDismissed: boolean;
  premiumDismissed: boolean;
};

const Sidebar: React.FC<Props & SideBarProps> = ({ ...props }) => {
  let lang = localStorage.getItem('lang') || navigator.language;
  const { langFlg, langFullName } = GetLanguageMetadata(lang);
  const { shutdown } = useIntercom();
  const [state, setState] = useState<State>({
    animating: false,
    boardListVisible: false,
    boardMenuItems: [],
    accountsVisible: false,
    tutorialDismissed: false,
    premiumDismissed: false,
    getStartedModalVisible: false,
    isBoardsExpanded: false,
    isMenuVisible: false,
    imagesPreloaded: false,
    synchronizing: false,
    boardTitle: '',
    lang: lang || '',
    langFlg: langFlg || '',
    langFullName: langFullName || '',
    editing: {},
  });

  const [premiumModalVisible, setPremiumModalVisible] = useState(false);

  useEffect(() => {
    if (props.canCloseModal === true) {
      setPremiumModalVisible(() => false);
    }
  }, [props.canCloseModal, setPremiumModalVisible])

  useEffect(() => {
    const firstTimeKey = `firstTime-${props.accountId}`;
    if (props.accountId && localStorage.getItem(firstTimeKey) !== 'true') {
      setTimeout(() => {
        localStorage.setItem(firstTimeKey, 'true');
        showGetStartedModal();
      }, 1000);
    }

    const logoImage = (new Image().src = imgLogo);
    const logoImageFull = (new Image().src = imgLogoFull);
    Promise.all([logoImage, logoImageFull]).then(() =>
      setState((state) => ({
        ...state,
        imagesPreloaded: true
      }))
    );
  }, []);

  useEffect(() => {
    if (props.boards && props.boards.length) {
      const items: BoardMenuItem[] = [];
      for (let i = 0; i < props.boards.length; i++) {
        const board = props.boards[i];
        const item = new BoardMenuItem(
          board.title,
          board.id,
          board.id === props.activeBoardId,
        );
        items.push(item);
      }
      setState((state) => ({
        ...state,
        boardMenuItems: items
      }));
    }
  }, [props.boards])

  const handleDismissMenu = () => {
    setState((state) =>
    ({
      ...state,
      animating: true,
      isMenuVisible: false,
    }));

    setTimeout(() => {
      setState((state) => ({
        ...state,
        animating: false,
      }))
    }, MENU_ANIMATION_DURATION);
  };
  const handleBoardSelect = (selectedItem: BoardMenuItem) => {
    const { onBoardSelect, handleShowMyAccountPanel } = props;
    const { boardMenuItems } = state;
    const newMenuItems = boardMenuItems.map(item => ({
      ...item,
      isSelected: item.boardId === selectedItem.boardId,
    }));
    setState((state => ({ ...state, boardMenuItems: newMenuItems })));
    onBoardSelect(selectedItem.boardId);
    handleShowMyAccountPanel(false);
  };

  const handleBoardUpdate = (updatedItem: BoardMenuItem, title: string) => {
    const { boards, onBoardUpdate } = props;
    const board = boards.find(item => item.id === updatedItem.boardId);
    if (!board) {
      return;
    }
    onBoardUpdate({
      ...board,
      title,
    });
  };

  const handleSignOut = () => {
    props.onSignOutClick();
    setState((state) => ({
      ...state,
      boardMenuItems: [],
    }));
    shutdown();
  };

  const handleSyncClick = async () => {
    const start = Date.now();
    const spinDuration = 2000;
    try {
      setState((state) => ({ ...state, synchronizing: true }));
      await props.onBoardSyncClick();
    } finally {
      const end = Date.now();
      const elapsed = end - start;
      const untilNextAnimationEnd = spinDuration - (elapsed % spinDuration);
      setTimeout(() => {
        setState((state) => ({ ...state, synchronizing: false }));
      }, untilNextAnimationEnd);
    }
  };

  const toggle = () => {
    // cancelSubSubs();
    const { animating, isMenuVisible } = state;
    if (animating) {
      return;
    }
    setState((state) => ({ ...state, animating: true, isMenuVisible: !isMenuVisible }));
    setTimeout(() => {
      setState((state) => ({ ...state, animating: false }));
    }, MENU_ANIMATION_DURATION);
  };

  const activelanguage = (lang: string, flag: string, fullName: string) => {
    setState((state) => ({ ...state, lang, langFullName: fullName, langFlg: flag }));
    localStorage.setItem('lang', lang);
    i18n.changeLanguage(lang);
    props.onChangeLanguage(lang);
  };

  const showBoards = () => {
    let data: any = localStorage.getItem('AccountInfo');
    const accountInfo = JSON.parse(data);
    let account: any = localStorage.getItem('accountId');
    const accountData = JSON.parse(account);
    if (accountData == null || accountInfo == null) {
      props.onSignOutClick();
    } else {
      setState((state) => ({
        ...state,
        boardListVisible: true,
      }));
    }
  };

  const showPremiumModal = () => {
    setPremiumModalVisible(() => true);
    props.setGoPremiumModalVisible(true);
    // props.handleSetBackgroundFreezeState(true);
  };

  const hidePremiumModal = () => {
    setPremiumModalVisible(() => false);
    // props.handleSetBackgroundFreezeState(false);
  };

  const showGetStartedModal = () => {
    setState((state) => ({
      ...state,
      getStartedModalVisible: true,
    }));

    props.handleSetBackgroundFreezeState(true);
  };

  const hideGetStartedModal = () => {
    setState((state) => ({
      ...state,
      getStartedModalVisible: false,
    }));

    props.handleSetBackgroundFreezeState(false);
  };

  const hideAccount = () => {
    setState((state) => ({
      ...state,
      accountsVisible: false,
    }));

    handleDismissMenu();
  };

  const hideBoards = () => {
    setState((state) => ({
      ...state,
      boardListVisible: false,
    }));

    handleDismissMenu();
  };

  const hideaccount = () => {
    setState((state) => ({
      ...state,
      boardListVisible: false,
    }));

    handleDismissMenu();
  };

  const handleDismissTutorial = (event: MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    setState((state) => ({
      ...state,
      tutorialDismissed: true,
    }));
  };

  const handleDismissPremium = (event: MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    setState((state) => ({
      ...state,
      premiumDismissed: true,
    }));
  };

  const formatWebTrialEnds = (webTrialEnds: number | null) => {
    if (webTrialEnds) {
      const date = new Date(webTrialEnds);
      const formattedDate = new Intl.DateTimeFormat(state.lang, {
        dateStyle: 'long',
        timeStyle: 'short',
      }).format(date);
      return formattedDate;
    }
    return null;
  };

  const openModal = () => {
    setState((state) => ({
      ...state,
      boardListVisible: true,
    }));
  };

  const openModals = () => {
    setState((state) => ({
      ...state,
      accountsVisible: true,
    }));
  };

  const showAccount = () => {
    let data: any = localStorage.getItem('AccountInfo');
    const accountInfo = JSON.parse(data);
    if (accountInfo === null) {
      props.onSignOutClick();
    } else {
      setState((state) => ({
        ...state,
        accountsVisible: true,
      }));
      toggle();
    }
  };

  const getName = () => {
    const filteredResult = state.boardMenuItems.filter(
      board => board.isSelected,
    );
    return filteredResult[0]?.title || '';
  }

  const toggleBoards = () => {
    const newValue = !state.isBoardsExpanded;
    setState((state) => ({
      ...state,
      isMenuExpanded: true,
      isBoardsExpanded: newValue,
    }));
    // when board list expands ensure menu expands as well
    if (newValue) {
      setMenuExpanded(true);
    }
  };

  const setBoardsExpanded = (newValue: boolean) => {
    setState((state) => ({
      ...state,
      isBoardsExpanded: newValue,
    }));
  };

  const toggleMenu = () => {
    const newValue = !props.isMenuExpanded;
    props.onMenuToggle(newValue);
    // when menu collapses ensure board list collapses as well
    if (!newValue) {
      setBoardsExpanded(false);
    }
  };
  const setMenuExpanded = (newValue: boolean) => {
    setState((state) => ({
      ...state,
      isBoardsExpanded: newValue,
    }));

    props.onMenuToggle(newValue);
  };

  const {
    activeBoardId,
    addBoardModalVisible,
    webTrialEnds,
    isBoldColors,
    isCompletedCards,
    storyCount,
    isMenuExpanded,
    onAddBoardClick,
    onAddBoardsClick,
    onAddBoardTemplateClick,
    onBoardCopied,
    onBoardDelete,
    onBoardDragEnd,
    onSignOutClick,
    handleToggle,
    handleToggleCompletedCards,
    t
  } = props;
  const {
    boardListVisible,
    accountsVisible,
    getStartedModalVisible,
    boardMenuItems,
    isMenuVisible,
    imagesPreloaded,
    synchronizing,
    editing,
    tutorialDismissed,
    premiumDismissed,
  } = state;

  return (
    <>
      <div
        className={`sidebar-container ${isMenuExpanded ? 'expanded' : 'collapsed'
          }`}>
        <div className="sidebar-top">
          <div className="header_logo">
            {!isMenuExpanded && (
              <img
                className="header_logo_img_collapsed"
                src={imgLogo}
                alt=""
                loading="eager"
                style={{ visibility: imagesPreloaded ? 'visible' : 'hidden' }}
                onClick={toggleMenu}
              />
            )}
            {isMenuExpanded && (
              <img
                className="header_logo_img_expanded"
                src={imgLogoFull}
                alt=""
                loading="eager"
                style={{ visibility: imagesPreloaded ? 'visible' : 'hidden' }}
                onClick={toggleMenu}
              />
            )}
          </div>
          <div className="expand_collapse" onClick={toggleMenu}>
            <button
              onClick={toggleMenu}
              className="expand_collapse_button">
              <FontAwesomeIcon
                icon={
                  props.isMenuExpanded ? faChevronLeft : faChevronRight
                }
              />
            </button>
          </div>
        </div>
        <div className="home_button">
          {!isMenuExpanded && (
            <div className="home_button_top">
              <img
                className="home_button_img"
                src={imgHome}
                alt=""
                onClick={toggleBoards}
              />
              <div className="home_button_seperator"></div>
            </div>
          )}

          {isMenuExpanded && (
            <>
              <div className="home_button_top" onClick={toggleBoards}>
                <img className="home_button_img" src={imgHome} alt="" />
                <label className="expanded_text">{t('Board.MyBoards')}</label>
                <span className="boardCount">
                  {props.boardCount || 0}
                </span>
                <ToggleBoardsButton onClick={toggleBoards}>
                  <FontAwesomeIcon
                    icon={
                      state.isBoardsExpanded
                        ? faChevronUp
                        : faChevronDown
                    }
                  />
                </ToggleBoardsButton>
              </div>
              {!state.isBoardsExpanded && (
                <div className="home_button_seperator"></div>
              )}
              {state.isBoardsExpanded && (
                <>
                  <BoardList
                    boardMenuItems={boardMenuItems}
                    addBoardModalVisible={addBoardModalVisible}
                    onAddBoardClick={onAddBoardClick}
                    onAddBoardTemplateClick={onAddBoardTemplateClick}
                    onBoardCopied={onBoardCopied}
                    onBoardDelete={onBoardDelete}
                    onBoardDragEnd={onBoardDragEnd}
                    onBoardSelect={handleBoardSelect}
                    onBoardUpdate={handleBoardUpdate}
                    onDismiss={hideBoards}
                    isModal={false}
                    isMenuExpanded={isMenuExpanded}
                  />
                  <div className="home_button_seperator"></div>
                </>
              )}
            </>
          )}
        </div>
        <div className="sidebar-bottom">
          {!tutorialDismissed && (
            <div
              onClick={showGetStartedModal}
              className={`getting_started_button${isMenuExpanded ? '_expanded' : '_collapsed'
                }`}>
              <div>
                <img
                  className="getting_started_img"
                  src={imgGettingStarted}
                  alt=""
                />
                {isMenuExpanded && (
                  <label className="expanded_text">{t('SideMenu.GettingStarted')}</label>
                )}
              </div>
              {isMenuExpanded && (
                <DismissTutorialButton onClick={handleDismissTutorial}>
                  <FontAwesomeIcon
                    icon={faXmark}
                    color="#3D3D3D"
                    style={{ fontSize: '20px' }}
                  />
                </DismissTutorialButton>
              )}
            </div>
          )}
          <a
            href={buildKanbanaRedirectUrl(lang ?? 'en', '/help_center')}
            target="_blank"
            rel="noopener noreferrer">
            <div
              className={`help_button${isMenuExpanded ? '_expanded' : '_collapsed'
                }`}>
              <img className="help_button_img" src={imgHelp} alt="" />
              {isMenuExpanded && (
                <label className="expanded_text">{t('SideMenu.HelpAndSupport')}</label>
              )}
            </div>
          </a>
          {/*
            Hide temporarily while it's not defined what to do.
            <div
              onClick={this.props.onBoardSyncClick}
              className={`sync_button${
                isMenuExpanded ? '_expanded' : '_collapsed'
              }`}>
              <img className="sync_button_img" src={imgSync} alt="" />
              {isMenuExpanded && <label className="expanded_text">{t('SideMenu.Sync')}</label>}
            </div> */}
          {!premiumDismissed && isFreeAccount() && (
            <button className="goPremiumLeft" style={{ padding: isMenuExpanded ? '10px 16px' : '10px 8px' }} onClick={showPremiumModal}>
              {!isMenuExpanded && (
                <img
                  src={pointerClickIcon}
                  alt=""
                  className="go_premium_left_pointer_img"
                />
              )}
              {isMenuExpanded && (
                <div className="goPremiumExpanded">
                  <label className="goPremiumLeftTitle">{t('Premium.GoPremium')}</label>
                  <label className="goPremiumLeftText">
                    {t('Premium.RunningOutOfSpace')}
                  </label>
                  <div className="progress-bar-outer">
                    <div
                      className="progress-bar-inner"
                      style={{
                        width: `${((storyCount || 0) / 20) * 100}%`,
                      }}></div>
                  </div>
                  <label className="goPremiumLeftText goPremiumLeftGlow">
                    <b>{storyCount || 0}  {t('Premium.OutOf')} 20</b>
                  </label>
                  <DismissPremiumButton onClick={handleDismissPremium}>
                    <FontAwesomeIcon
                      icon={faXmark}
                      color="#fff"
                      style={{ fontSize: '20px' }}
                    />
                  </DismissPremiumButton>
                </div>
              )}
            </button>
          )}
          <div
            className={`account_button${isMenuExpanded ? '_expanded' : '_collapsed'
              }`}
            onClick={props.handleClickMyAccount}>
            <div>
              {/* <img className="account_button_img" src={faUserCircle} alt="" /> */}
              <FontAwesomeIcon icon={faUserCog} size="1x" color="#3D3D3D" />
              {isMenuExpanded && (
                <label className="expanded_text">
                  {t('SideMenu.MyAccount')}
                </label>
              )}
            </div>
          </div>
          <div className={`logout_button${isMenuExpanded ? '_expanded' : '_collapsed'
            }`}>
            <SignOutButton title="Signout" onClick={handleSignOut}>
              <FontAwesomeIcon
                icon={faArrowRightFromBracket}
                size="1x"
                color="#3D3D3D"
                style={{ fontSize: '20px' }}
              />
              {isMenuExpanded && (
                <label className="expanded_text">{t('SideMenu.Logout')}</label>
              )}
            </SignOutButton>
          </div>
        </div>
      </div>
      <div
        className={`fixed_header${isMenuExpanded ? '_expanded' : '_collapsed'
          }`}>
        {webTrialEnds && (
          <WebTrialMessage>
            {t('Premium.AccessWillExpire')}{' '}
            {formatWebTrialEnds(webTrialEnds)}.<br />
            {t('Premium.KeepAccess')}
          </WebTrialMessage>
        )}
        {/*<div style={{ display: 'inline-block' }}>
             <MenuBar style={{padding:10}}>
              <SidebarButton onClick={this.toggle}>
                <FontAwesomeIcon icon={faBars} size="2x" style={styles.icon} />
              </SidebarButton>
              {activeBoardId && (<img className='header_logo' src={imgLogo} alt="" />)}
            </MenuBar>
          </div>*/}
        {activeBoardId && (
          !TEMPLATES.map(x => x.id).includes(activeBoardId) ? <Dropdown
            open={boardListVisible}
            onOpenChange={() => setState((state) => ({ ...state, boardListVisible: !boardListVisible }))}
            dropdownRender={() =>
              <BoardList
                boardMenuItems={boardMenuItems}
                addBoardModalVisible={addBoardModalVisible}
                onAddBoardTemplateClick={onAddBoardTemplateClick}
                onAddBoardClick={onAddBoardClick}
                onBoardCopied={onBoardCopied}
                onBoardDelete={onBoardDelete}
                onBoardDragEnd={onBoardDragEnd}
                onBoardSelect={handleBoardSelect}
                onBoardUpdate={handleBoardUpdate}
                onDismiss={hideBoards}
                isModal={true}
                isMenuExpanded={isMenuExpanded}
              />}
            placement="bottomLeft"
            trigger={['click']}>
            <DropdownBoardButton ghost type='link' size="large">
              <FlexBoard>
                {getName()}
                <BoardCount>{props.boardCount || 0}</BoardCount>
                {boardListVisible ? <UpOutlined /> : <DownOutlined />}
              </FlexBoard>
            </DropdownBoardButton>
          </Dropdown> :
            <TemplateTitle>
              <ArrowLeftOutlinedStyled onClick={onAddBoardTemplateClick} /> {t('Template.Title')} - {t('Template.' + TEMPLATES.find(x => x.id === activeBoardId)?.id)}
              <Button onClick={onAddBoardsClick} size='large' type='primary'>{t('Template.ButtonAddTemplateText')}</Button>
            </TemplateTitle>
        )}
        <LanguageSelector
          lang={state.lang}
          langFlg={state.langFlg}
          langFullName={state.langFullName}
          activelanguage={activelanguage}
        />
        {activeBoardId && (
          <>
            <div className="boldColors">
              <div className="toggle-switch-container">
                <div className="toggle-switch" onClick={handleToggle}>
                  <input
                    type="checkbox"
                    className="toggle-switch-checkbox"
                    checked={isBoldColors}
                  />
                  <span className="toggle-switch-slider"></span>
                </div>
                <label className="toggle-switch-label">{t('Board.BoldColors')}</label>
              </div>
            </div>
            <CompletedCardsSelector
              completedStories={props.completedStories}
              selectedPeriod={props.selectedPeriodCompletedCards}
              handleToggleCompletedCards={handleToggleCompletedCards}
              isCompletedCards={isCompletedCards}
              selectPeriod={props.handleChangePeriodCompletedCards}
            />
          </>
        )}
        {activeBoardId && isFreeAccount() && (
          <div className="searchGoPremium">
            {/* <SearchInput /> */}
            <button className="goPremiumTop" onClick={showPremiumModal}>
              {t('Premium.GoPremium')}!
              <img
                src={pointerClickIcon}
                alt=""
                className="go_premium_top_pointer_img"
              />
            </button>
          </div>
        )}
      </div>
      <Menu
        isVisible={isMenuVisible}
        menuItems={boardMenuItems}
        onBoardSelect={handleBoardSelect}
        onBoardsClick={showBoards}
        onAccountClick={showAccount}
        onDismiss={handleDismissMenu}
        onSignOutClick={onSignOutClick}
      />
      {accountsVisible && (
        <SubModal
          onDismiss={hideAccount}
          CheckoutForm={function (): void {
            throw new Error('Function not implemented.');
          }}
          App={function (): void {
            throw new Error('Function not implemented.');
          }}
        />
      )}
      {premiumModalVisible === true && (
        <PremiumModal hidePremiumModal={hidePremiumModal} />
      )}
      {getStartedModalVisible && (
        <GetStartedModal hide={hideGetStartedModal} />
      )}
    </>
  );
}

const styles = {
  icon: {
    color: '#e4e4e4',
    height: '22px',
    marginLeft: '6px',
    marginTop: '3px',
  },
};

const MenuBar = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 5px;
`;

const WebTrialMessage = styled.div`
  color: #ffffff;
  align-items: center;
  padding: 6px;
  font-weight: bold;
  vertical-align: center;
  background-color: #aaaaaa;
`;

const ToggleBoardsButton = styled.button`
  background-color: transparent;
  border: none;
`;

const SideBarButton = css`
  background-color: transparent;
  border: none;
  border-radius: 6px;
  padding: 1px 6px;
  transition: background-color 0.3s ease;
  background-color: transparent;
`;

const DismissTutorialButton = styled.a`
  ${SideBarButton}
  margin-left: 8px;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const DismissPremiumButton = styled.a`
  ${SideBarButton}
  position: absolute;
  top: 0px;
  right: 0px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.25);
  }
`;

const SignOutButton = styled.a`
  ${SideBarButton}

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

export default withTranslation()(Sidebar)
