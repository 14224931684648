import React, { Component } from 'react';
import ReactGA from 'react-ga';
import 'firebase/analytics';
import { IDBPDatabase } from 'idb';
import ForgotPassword from './account/ForgotPassword';
import SignUp from './account/SignUp';
import { AccountPanelWrapper } from './account/AccountPanelWrapper';
import * as AsyncService from './api/AsyncService';
import { BoardDetailsContainer } from './board/BoardDetails.container';
import { AppNavigation, PeriodCompletedCards } from './board/ViewModels';
import Spinner from './resources/Spinner';
import { SidebarContainer } from './sidebar/Sidebar.container';
import i18n from 'i18next';
import { TemplateContainer } from './board/Template.container';
import { TEMPLATES } from './utils/template';
import ForgotHowLoggedIn from './account/ForgotHowLoggedIn';
import 'dayjs/locale/de';
import 'dayjs/locale/fr';
import 'dayjs/locale/pt';
import 'dayjs/locale/hi';
import 'dayjs/locale/ja';
import 'dayjs/locale/es';
import 'dayjs/locale/ru';
import 'dayjs/locale/ko';
import { ConfigProvider } from 'antd'
import service from './api/Service';


import {
  ContextValues,
  RESET_STATE,
  SET_ACCOUNT_ID,
  SET_ACTIVE_BOARD,
  Synchronize,
} from './store';
import { colors } from './theme';
import { Database } from './typings/database';
import { Trigger } from './typings/synchronize';
import LogIn from './account/Login';
import { AccountPanel } from './account/AccountPanel';
import { orderBy } from 'lodash';
import { isDesktopApp } from '@todesktop/client-core/platform/todesktop';
import { webContents } from '@todesktop/client-core';
import { readFile } from "@todesktop/client-fs";
import { setUserId } from 'firebase/analytics';
import { analytics } from '.';
import { IntercomWidget } from './components/IntercomWidget';
import { Locale } from 'antd/lib/locale';
import { GetLanguageMetadata } from './utils/lang';

const REFRESH_EVERY_5_MINS = 5 * 60 * 1000

ReactGA.initialize(process.env['REACT_APP_MEASUREMENT_ID']!);
type Props = {
  database?: IDBPDatabase<Database>;
  store: ContextValues;
  synchronize: Synchronize;
  handleSignOutClick?: () => void;
  changeLanguageHandler?: () => void;
};
type State = {
  accountId?: string;
  activeBoardId?: string;
  canCopyBoard?: boolean;
  lastSyncAt?: number;
  loading: boolean;
  navigation: AppNavigation;
  webTrialEnds?: number;
  isMenuExpanded: boolean;
  isBoardsExpanded: boolean;
  isBoldColors: boolean;
  showMyAccountPanel: boolean;
  freezeBackground: boolean;
  provider?: string;
  loadingRequest: boolean;
  isCompletedCards: boolean;
  selectedPeriodCompletedCards: PeriodCompletedCards;
  currentAntDLocale: Locale;
  langShortValue: string;
};
export class App extends Component<Props, State> {
  private inactivityTimeout: NodeJS.Timeout | null = null;
  constructor(props: Props) {
    super(props);
    var userLang = localStorage.getItem('lang') || navigator.language;
    i18n.changeLanguage(userLang);
    const lang = GetLanguageMetadata(userLang);
    localStorage.setItem('lang', lang.lang);
    this.state = {
      loading: false,
      navigation: 'board',
      isMenuExpanded: false,
      isBoardsExpanded: false,
      isBoldColors: true,
      showMyAccountPanel: false,
      freezeBackground: false,
      loadingRequest: false,
      isCompletedCards: false,
      selectedPeriodCompletedCards: {
        period: 1,
        periodType: 'Days'
      },
      currentAntDLocale: lang.currentAntdLocale,
      langShortValue: lang.langShortValue,
    };

  }

  componentDidMount() {
    if (isDesktopApp()) {
      webContents.setWindowOpenRules({
        // @ts-ignore
        ref: undefined, // web contents ref is optional, defaults to the current window.
        rules: [
          {
            regex: '.*google\\.com.*',
            options: {
              action: 'allow',
              overrideBrowserWindowOptions: {
                titleBarStyle: 'default',
                width: 550,
                height: 600
              }
            }
          },
        ],
        fallback: {
          action: 'openInBrowser'
        }
      });

      // Check local storage for language
      let kl_key = "kanbana_language";
      let kl_key_check = "kanbana_language_loaded";
      let lang_check = localStorage.getItem(kl_key_check);
      if (!lang_check) {
        // If we have not loaded the language, load it now from the file and set it once only
        try {
          // Get distributor from install file
          let path = kl_key + ".txt";
          readFile("temp", path)
            .then((contents) => {
              let lang = contents;
              // Set distributor in local storage & state
              localStorage.setItem("lang", lang);
              localStorage.setItem(kl_key_check, "loaded");
            })
            .catch((reason) => {
              console.log(reason);
            });
        }
        catch (e) {
          console.log(e);
        }
      }

      // Check local storage for distributor
      let kd_key = "kanbana_distributor";
      let kd_date_key = "kanbana_distributor_date";
      let kd_key_check = "kanbana_distributor_loaded";
      let distributor_check = localStorage.getItem(kd_key_check);
      if (distributor_check) {
        // If we have already loaded the distributor, read it from local storage
        let distributor = localStorage.getItem(kd_key);
        if (distributor) {
          // Set distributor in state
          this.props.store.state.distributor = distributor;
        }
      }
      else {
        // If we have not loaded the distributor, load it now from the file
        try {
          // Get distributor from install file
          let path = kd_key + ".txt";
          readFile("temp", path)
            .then((contents) => {
              let distributor = contents;
              // Set distributor in local storage & state
              localStorage.setItem(kd_key, distributor);
              localStorage.setItem(kd_date_key, new Date().toDateString());
              this.props.store.state.distributor = distributor;
              localStorage.setItem(kd_key_check, "loaded");
              this.reportDesktopLaunch(distributor);
            })
            .catch((reason) => {
              console.log(reason);
              this.reportDesktopLaunch("Unknown");
            });
        }
        catch (e) {
          console.log(e);
          this.reportDesktopLaunch("Unknown");
        }
      }
    }

    document.body.style.backgroundColor = colors.lightgrey;
    this.tryToUpdateStateFromLocalStorage();

    // Set up the inactivity timeout
    this.setInactivityTimeout();

    // Add event listeners to reset the inactivity timeout when the user interacts with the app
    document.addEventListener('mousemove', this.resetInactivityTimeout);
    document.addEventListener('keydown', this.resetInactivityTimeout);
    window.onbeforeunload = () => {
      localStorage.setItem('appState', JSON.stringify(this.state));
    }

    const savedState = localStorage.getItem('appState');
    if (savedState) {
      this.setState(JSON.parse(savedState));
    }
  }

  componentWillUnmount(): void {
    // Clear the inactivity timeout and remove event listeners when the component is unmounted
    this.clearInactivityTimeout();
    document.removeEventListener('mousemove', this.resetInactivityTimeout);
    document.removeEventListener('keydown', this.resetInactivityTimeout);
  }

  private setInactivityTimeout = () => {
    // Clear any existing inactivity timeout
    this.clearInactivityTimeout();

    // Set up a new inactivity timeout that reloads the page after 1 minute
    this.inactivityTimeout = setTimeout(() => {
      window.location.reload();
    }, REFRESH_EVERY_5_MINS);
  };

  private clearInactivityTimeout = () => {
    if (this.inactivityTimeout) {
      clearTimeout(this.inactivityTimeout);
      this.inactivityTimeout = null;
    }
  };

  private resetInactivityTimeout = () => {
    // Clear the existing inactivity timeout
    this.clearInactivityTimeout();

    // Set up a new inactivity timeout
    this.setInactivityTimeout();
  };

  reportDesktopLogin = (distributor?: string) => {
    // Check if "Login" event has been sent
    let login_key = "kanbana_event_login";
    let login_check = localStorage.getItem(login_key);
    if (!login_check) {
      // If we have not sent the event, send it now
      fetch("https://web-api.kanbana.com/desktopEvent?d=" + distributor + "&e=Login");
      localStorage.setItem(login_key, "sent");
    }
  };
  reportDesktopLaunch = (distributor: string) => {
    // Check if "Launch" event has been sent
    let launch_key = "kanbana_event_launch";
    let launch_check = localStorage.getItem(launch_key);
    if (!launch_check) {
      // If we have not sent the event, send it now
      fetch("https://web-api.kanbana.com/desktopEvent?d=" + distributor + "&e=Launch");
      localStorage.setItem(launch_key, "sent");
    }
  };
  initializeSync = async (accountId: string) => {
    const {
      store: { dispatch },
      synchronize,
    } = this.props;

    try {
      const { boards } = await synchronize({
        accountId,
        trigger: Trigger.SignIn,
        pusher: 2
      });

      let activeBoardId = "";

      if (boards.length) {
        const storedJSON = localStorage.getItem(accountId);
        if (!storedJSON) {
          activeBoardId = orderBy(boards.filter(board => board.deletedAt == null), board => board.priority)[0].id;
          dispatch({
            type: SET_ACTIVE_BOARD,
            payload: activeBoardId,
          });
        }
      }

      localStorage.setItem(
        'accountId',
        JSON.stringify({
          accountId,
          lastBoardId: activeBoardId,
        }),
      );

      const language = localStorage.getItem('lang') || navigator.language;
      const lang = GetLanguageMetadata(language);
      service.changeLanguage(lang.langShortValue, accountId);
    } finally {
      let accountvk = localStorage.getItem('accountId');
      let accountnk = localStorage.getItem('AccountInfo');
      if (!accountvk || !accountnk) {
        this.handleSignOutClick();
      } else {
        this.setState({ loading: false, navigation: 'board' });
      }
    }
  };
  tryToUpdateStateFromLocalStorage = async () => {
    const {
      store: { dispatch },
    } = this.props;
    this.setState({
      loading: true,
    });
    let accountId = localStorage.getItem('com.kanbana.account.id');
    if (!accountId) {
      this.setState({
        navigation: 'login',
        loading: true,
      });
      this.reloadData();
      return;
    }

    dispatch({
      type: SET_ACCOUNT_ID,
      payload: accountId,
    });
    try {
      const accountResponse = await AsyncService.getAccount(accountId);
      this.setState({
        webTrialEnds: accountResponse.webTrialEndsAt,
        canCopyBoard: accountResponse.canCopyBoard,
      });
      setUserId(analytics, accountId);
      this.initializeSync(accountId);
    } catch (e) {
      if (
        (e as { response?: { status?: number } })?.response?.status === 403 ||
        (e as { response?: { status?: number } })?.response?.status === 404
      ) {
        this.handleSignOutClick();
        this.setState({
          navigation: 'login',
          loading: true,
          isMenuExpanded: false,
          isBoardsExpanded: false,
          isBoldColors: true,
          showMyAccountPanel: false,
          freezeBackground: false,
          isCompletedCards: false,
          selectedPeriodCompletedCards: {
            period: 1,
            periodType: 'Days'
          },
        });
        this.reloadData();
      }
    }
  };

  callbackOpenForgotPassword = () => {
    this.setState({
      navigation: 'forgotpassword',
    });
  };
  callbackSignInSucceeded = (accountId: string) => {
    if (isDesktopApp()) {
      this.reportDesktopLogin(this.props.store.state.distributor ?? "Unknown");
    }
    localStorage.setItem('com.kanbana.account.id', accountId);
    this.tryToUpdateStateFromLocalStorage();
  };
  handleSignOutClick = async () => {
    const {
      database,
      store: { dispatch },
    } = this.props;
    const total = localStorage.length;
    for (let index = total - 1; index >= 0; index--) {
      const element = localStorage.key(index) as string;
      if (element?.startsWith('firstTime') || element === 'lang') continue;

      localStorage.removeItem(element);
    }

    dispatch({ type: RESET_STATE, payload: null });
    if (database) {
      await Promise.all([
        database.clear('boards'),
        database.clear('stages'),
        database.clear('stories'),
      ]);
    }
    this.setState({
      navigation: 'login',
      loading: true,
      isBoardsExpanded: false,
      isMenuExpanded: false,
      showMyAccountPanel: false,
    });
    this.reloadData();
  };
  handleLoginClick = async () => {
    this.setState({
      navigation: 'login',
      loading: true,
    });
    this.reloadData();
  };
  handleSignupClick = async () => {
    this.setState({
      navigation: 'signup',
      loading: true,
    });
    this.reloadData();
  };
  callbackOpenForgotHowToLoggedIn = async () => {
    this.setState({
      navigation: 'forgothowtologgedin',
      loading: true,
      provider: undefined
    });
    this.reloadData();
  };
  callbackSearchLogin = async (email: string) => {
    this.setState({
      loadingRequest: true
    });
    var provider = await AsyncService.getAccountProvider(email);
    this.setState({
      provider: provider,
      loadingRequest: false
    });
  };
  callbackForgotPasswordEmailSent = () => {
    this.setState({
      navigation: 'login',
      loading: true,
    });
    this.reloadData();
  };
  reloadData() {
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 1000);
  }

  handleShowMyAccountPanel = (value: boolean) => {
    this.setState({
      showMyAccountPanel: value,
    });
  }

  toggleBoldColors = () => {
    this.setState({
      isBoldColors: !this.state.isBoldColors,
    });
  };

  toggleCompletedCards = () => {
    this.setState({
      isCompletedCards: !this.state.isCompletedCards
    })
  }

  handleChangePeriodCompletedCards = (value: PeriodCompletedCards) => {
    this.setState({
      selectedPeriodCompletedCards: value
    })
  }

  handleMenuToggle = (value: boolean) => {
    this.setState({
      isMenuExpanded: value,
    });
  };

  handleMyAccountPanelToggle = (value?: boolean) => {
    const nextValue =
      value !== undefined ? value : !this.state.showMyAccountPanel;

    this.setState({
      showMyAccountPanel: nextValue,
    });

    this.handleSetBackgroundFreezeState(nextValue);
  };

  handleSetBackgroundFreezeState = (value: boolean) => {
    this.setState({
      freezeBackground: value,
    });
  };

  handleChangeLanguage = (lang: string) => {
    const { currentAntdLocale, langShortValue } = GetLanguageMetadata(lang);
    this.setState({ loading: true, currentAntDLocale: currentAntdLocale });

    setTimeout(() => {
      this.setState({ loading: false });
    }, 300);
  }

  render() {
    const {
      store: { state },
    } = this.props;
    const {
      canCopyBoard,
      loading,
      navigation,
      webTrialEnds,
      isMenuExpanded,
      isBoldColors,
      isCompletedCards,
      selectedPeriodCompletedCards,
      langShortValue
    } = this.state;
    const { accountId, activeBoardId } = state;
    let isTemplate = false;
    const intercomAppId = process.env.REACT_APP_INTERCOM_APP_ID;

    if (activeBoardId && TEMPLATES.map(x => x.id).includes(activeBoardId)) {
      isTemplate = true;
    }
    if (loading == true) {
      return <Spinner />;
    }
    if (navigation === 'board') {
      const accountId = JSON.parse(localStorage.getItem('AccountInfo') || '{}')?.Id;

      window.history.pushState({}, document.title, '/board');
      return (
        <div className="Fixed_">
          <div
            style={{
              overflow: this.state.freezeBackground ? 'hidden' : undefined,
            }}>
            <SidebarContainer
              onChangeLanguage={this.handleChangeLanguage}
              activeTemplateId={activeBoardId}
              canCopyBoard={canCopyBoard}
              webTrialEnds={webTrialEnds}
              isBoldColors={isBoldColors}
              isMenuExpanded={isMenuExpanded}
              handleShowMyAccountPanel={this.handleShowMyAccountPanel}
              onSignOutClick={this.handleSignOutClick}
              handleToggle={this.toggleBoldColors}
              onMenuToggle={value => this.handleMenuToggle(value)}
              handleClickMyAccount={() => {
                this.handleMyAccountPanelToggle();
              }}
              handleSetBackgroundFreezeState={this.handleSetBackgroundFreezeState}
              isCompletedCards={isCompletedCards}
              selectedPeriodCompletedCards={selectedPeriodCompletedCards}
              handleChangePeriodCompletedCards={this.handleChangePeriodCompletedCards}
              handleToggleCompletedCards={this.toggleCompletedCards}
            />
            <AccountPanelWrapper
              panelOpen={this.state.showMyAccountPanel}
              sidebarExpanded={isMenuExpanded}
              handleClickCloseButton={() => {
                this.handleMyAccountPanelToggle(false);
              }}>
              <AccountPanel
                notifications={
                  {
                    /*[TAB_TITLE.PLANS]: 2,
                  [TAB_TITLE.EMAIL_NOTIFICATIONS]: 2*/
                  }
                }
                database={this.props.database}
                synchronize={this.props.synchronize}
                onSignOutClick={this.handleSignOutClick}
              />
            </AccountPanelWrapper>
            <div
              className={`fixed_botttom${isMenuExpanded ? '_expanded' : '_collapsed'
                }`}>
              {isTemplate && <TemplateContainer accountId={activeBoardId!} />}
              {accountId && activeBoardId && !isTemplate && (
                <ConfigProvider locale={this.state.currentAntDLocale}>
                  <BoardDetailsContainer
                    accountId={accountId}
                    boardId={activeBoardId}
                    isBoldColors={isBoldColors}
                    isCompletedCards={isCompletedCards}
                    selectedPeriodCompletedCards={selectedPeriodCompletedCards}
                  />
                </ConfigProvider>
              )}
              <IntercomWidget userId={accountId} />
            </div>
          </div>
        </div>
      );
    } else if (navigation === 'signup') {
      window.history.pushState({}, document.title, '/signup');
      return (
        <SignUp
          callbackSignInSucceeded={this.callbackSignInSucceeded}
          callbackOpenForgotPassword={this.callbackOpenForgotPassword}
          onLogInClick={this.handleLoginClick}
        />
      );
    } else if (navigation === 'login') {
      window.history.pushState({}, document.title, '/login');
      return (
        <>
          <LogIn
            callbackOpenForgotHowToLoggedIn={this.callbackOpenForgotHowToLoggedIn}
            callbackSignInSucceeded={this.callbackSignInSucceeded}
            callbackOpenForgotPassword={this.callbackOpenForgotPassword}
            onLogInClick={this.handleSignupClick}
          />
          <IntercomWidget />
        </>
      );
    } else if (navigation === 'forgothowtologgedin') {
      window.history.pushState({}, document.title, '/forgothowtologgedin');
      return (
        <ForgotHowLoggedIn
          callbackSignInSucceeded={this.callbackSignInSucceeded}
          onLogInClick={this.handleSignupClick}
        />
      );
    }
    return (
      <ForgotPassword
        callbackForgotPasswordEmailSent={this.callbackForgotPasswordEmailSent}
      />
    );
  }
}
