import dayjs from "dayjs";
import { isEmpty, isNil } from "lodash";

export const isFreeAccount = () => {
  let data: any = localStorage.getItem('AccountInfo');
  const accountInfo = JSON.parse(data);
  return (accountInfo?.['subcode'] !== 'h' + accountInfo?.['Id'] + 'qp');
}

export const hasFastSpringCouponCode = () => {
  let data: any = localStorage.getItem('AccountInfo');
  const accountInfo = JSON.parse(data);
  const couponCode = accountInfo?.['FastSpringCouponCode'];
  return !isNil(couponCode) && !isEmpty(couponCode);
}

export const addFastSpringCouponCode = (coupon: string) => {
  let data: any = localStorage.getItem('AccountInfo');
  const accountInfo = JSON.parse(data);
  if (!isNil(accountInfo)) {
    accountInfo['FastSpringCouponCode'] = coupon;
    localStorage.setItem('AccountInfo', JSON.stringify(accountInfo));
  }
}

export const getAccountPlan = () => {
  let data: any = localStorage.getItem('AccountInfo');
  const accountInfo = JSON.parse(data);
  return accountInfo.SubscriptionPlan ?? "";
}

export const canCancelSubscription = () => {
  let data: any = localStorage.getItem('AccountInfo');
  const accountInfo = JSON.parse(data);
  return getAccountPlan().includes('FASTSPRING') && accountInfo.IsCancelled === false;
}

export const isCancelledPlan = () => {
  let data: any = localStorage.getItem('AccountInfo');
  const accountInfo = JSON.parse(data);
  return accountInfo.IsCancelled === true;
}

export const expiredPlanAt = () => {
  let data: any = localStorage.getItem('AccountInfo');
  const accountInfo = JSON.parse(data);
  const date = dayjs(accountInfo.ExpiresAt).format('MMM DD, YYYY');
  return date;
}

export const isFastSpringPremiumUser = () => {
  return getAccountPlan().includes('FASTSPRING');
}

export const getFastSpringCurrentPlan = () => {
  return getAccountPlan().includes("Monthly") ? "MONTHLY" : "YEARLY";
}

export const getEnableEmailNotification = () => {
  const data: any = JSON.parse(localStorage.getItem('AccountInfo') || '{}');
  if (data.hasOwnProperty('EnableEmailNotification')) {
    return data.EnableEmailNotification;
  }
  return true;
}

export const setEnableEmailNotification = (value: boolean) => {
  const data: any = localStorage.getItem('AccountInfo');
  const accountInfo = JSON.parse(data);
  if (!isNil(accountInfo)) {
    accountInfo['EnableEmailNotification'] = value;
    localStorage.setItem('AccountInfo', JSON.stringify(accountInfo));
  }
}

export const getAccountId = () => {
  const accountId = JSON.parse(localStorage.getItem('AccountInfo') || '{}')?.Id;
  return accountId;
}

export const getName = () => {
  const data: any = JSON.parse(localStorage.getItem('AccountInfo') || '{}');
  if (data.hasOwnProperty('Name')) {
    return data.Name;
  }
  return "";
}

export const addName = (name: string) => {
  let data: any = localStorage.getItem('AccountInfo');
  const accountInfo = JSON.parse(data);
  if (!isNil(accountInfo)) {
    accountInfo['Name'] = name;
    localStorage.setItem('AccountInfo', JSON.stringify(accountInfo));
  }
}